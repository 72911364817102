import {
	Inline,
	Alert,
	Heading,
	Badge,
	Box,
	Button,
	Icons,
	Table,
	Tooltip,
	TableDataCell,
	Stack,
	Text,
	Col,
	Component,
	Container,
	Divider,
	Dropdown,
	Grid,
	joinAttributes,
	RelativeTime,
	Spinner,
	Stars,
	TabContent,
	Tabs,
	TabsList,
	Time,
	TimeDuration,
	MoneySum,
	BoxOwnProps,
} from "@sembark-travel/ui/base"
import { NavLink, queryToSearch, ButtonLink } from "@sembark-travel/ui/router"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	utcTimestampToLocalDate,
	duration as createDuration,
	formatDate,
	getDiff,
	localOrUtcTimestampToLocalDate,
	getDiffBetweenLocalAndUtcTimestamp,
} from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import React, { useMemo } from "react"
import useSWR from "swr"
import { $PropertyType } from "utility-types"
import { generatePath, useNavigate } from "../router-utils"
import { collect } from "../utils"
import { GeneratedItineraryAsset } from "./GenerateItineraryAssets"
import { GivenPackagePriceDetailsInDialog, GiveQuote } from "./GiveQuote"
import ItineraryItem from "./ItineraryItem"
import { ShareQuoteInDialog } from "./ShareQuote"
import {
	IQuote,
	IQuoteDiff,
	ITrip,
	ITripQuote,
	useFetchQuote,
	getBasicInfoForQueryFromTrip,
	quotesXHR as XHR,
	useDiffBetweenLatestQuoteAndBookings,
} from "./store"
import {
	getDaywiseTransportsAndActivitiesForQuote,
	getTripQuoteDetailsDiff,
} from "./utils"
import {
	numberToLocalString,
	withOrdinalSuffix,
} from "@sembark-travel/number-utils"
import { PERMISSIONS, useCheckPermissions, useHasFeatureFlag } from "../Auth"
import { Money } from "@sembark-travel/ui/base"
import {
	TMoney,
	addMoneyFromDifferentCurrencies,
	createMoney,
	formatMoneyByIntl,
	makeCurrencyConverter,
	moneyParseByDecimal,
} from "@sembark-travel/money"

export default function Quotes({
	trip,
	children,
}: {
	trip: ITrip
	children?: (props: { defaultQuoteId: number }) => React.ReactNode
}) {
	const xhr = useXHR()
	const { data: tripQuotes } = useSWR<ITripQuote[]>(
		`trip/${trip.id}/quotes`,
		() => XHR(xhr).getQuotes(trip.id)
	)
	const { latest_given_quote } = trip
	const [convertedQuote, quoteLatestGiven, otherQuotes, afterConversionQuotes] =
		useMemo(
			() =>
				(tripQuotes || []).reduce<
					[
						ITripQuote | undefined,
						ITripQuote | undefined,
						ITripQuote[],
						ITripQuote[],
					]
				>(
					(
						[
							convertedQuote,
							quoteLatestGiven,
							otherQuotes,
							afterConversionQuotes,
						],
						quote
					) => {
						let pushItIntoTheList = true
						if (quote.given_quote?.used_for_conversion) {
							pushItIntoTheList = false
							convertedQuote = quote
						} else if (
							quote.given_quote &&
							latest_given_quote &&
							quote.given_quote.id === latest_given_quote.id
						) {
							pushItIntoTheList = false
							quoteLatestGiven = quote
						}
						if (quote.created_after_conversion) {
							pushItIntoTheList = false
							afterConversionQuotes.push(quote)
						}
						if (pushItIntoTheList) {
							otherQuotes.push(quote)
						}

						return [
							convertedQuote,
							quoteLatestGiven,
							otherQuotes,
							afterConversionQuotes,
						]
					},
					[undefined, undefined, [], []]
				),
			[tripQuotes, latest_given_quote]
		)
	return (
		<Box marginY="4">
			{!tripQuotes ? (
				<Spinner padding="4" alignCenter />
			) : !tripQuotes.length ? null : (
				<Tabs>
					<TabsList style={{ minWidth: "150px" }}>
						{afterConversionQuotes.length
							? afterConversionQuotes.map((quote) => (
									<QuoteLink
										quote={quote}
										key={quote.id}
										latestGiven={quote.id === quoteLatestGiven?.id}
									/>
								))
							: null}
						{convertedQuote ? (
							<QuoteLink
								quote={convertedQuote}
								key={convertedQuote.id}
								converted
								latestGiven={
									convertedQuote.given_quote &&
									latest_given_quote &&
									convertedQuote.given_quote.id === latest_given_quote.id
								}
							/>
						) : null}
						{afterConversionQuotes.length === 0 && quoteLatestGiven ? (
							<QuoteLink
								quote={quoteLatestGiven}
								key={quoteLatestGiven.id}
								latestGiven
							/>
						) : null}
						{otherQuotes.map((quote) => (
							<QuoteLink quote={quote} key={quote.id} />
						))}
					</TabsList>
					<TabContent
						bgColor="default"
						roundedRight="lg"
						overflow="auto"
						style={{ minHeight: "400px" }}
					>
						{children
							? children({
									defaultQuoteId: (afterConversionQuotes?.length &&
									quoteLatestGiven
										? quoteLatestGiven
										: convertedQuote ||
											quoteLatestGiven ||
											afterConversionQuotes[0] ||
											tripQuotes[0]
									).id,
								})
							: null}
					</TabContent>
				</Tabs>
			)}
		</Box>
	)
}

function QuoteLink({
	quote: tripQuote,
	latestGiven,
	converted,
}: {
	quote: ITripQuote
	latestGiven?: boolean
	converted?: boolean
}) {
	const {
		given_quote,
		quote,
		shared_by_tenant,
		shared_with_tenant,
		created_after_conversion,
		used_for_conversion,
		used_for_holding,
	} = tripQuote
	const allOptions = [tripQuote].concat(tripQuote.options || [])
	return (
		<NavLink
			key={tripQuote.id}
			to={generatePath("/trips/:tripId/quotes/:quoteId", {
				tripId: String(tripQuote.trip_id),
				quoteId: String(tripQuote.id),
			})}
			replace
			style={{
				minWidth: "150px",
			}}
			position="relative"
		>
			<Box
				position="absolute"
				top="0"
				right="0"
				paddingTop="1"
				paddingRight="2"
			>
				{used_for_conversion || used_for_holding ? (
					<Tooltip
						content={
							latestGiven
								? `Latest Quote used for ${
										used_for_conversion ? "Conversion" : "Holding"
									}`
								: `Used for ${used_for_conversion ? "Conversion" : "Holding"}`
						}
					>
						<Box>
							{used_for_conversion ? (
								<Icons.OkCircleSolid color="success" />
							) : (
								<Icons.FlagSolid color="warning" />
							)}
						</Box>
					</Tooltip>
				) : created_after_conversion ? (
					latestGiven ? (
						<Tooltip content={"Latest Quote after conversion"}>
							<Box>
								<Icons.OkCircleSolid color="primary" />
							</Box>
						</Tooltip>
					) : (
						<Tooltip content={"After Conversion Quote"}>
							<Box padding="1" display="inlineBlock">
								<Box
									display="inlineBlock"
									width="3"
									height="3"
									rounded="full"
									borderWidth="1"
									borderColor="primary_emphasis"
									bgColor="primary"
								></Box>
							</Box>
						</Tooltip>
					)
				) : latestGiven ? (
					<Tooltip content="Latest Quote">
						<Box padding="1" display="inlineBlock">
							<Box
								display="inlineBlock"
								width="3"
								height="3"
								rounded="full"
								borderWidth="1"
								borderColor="primary_emphasis"
								bgColor="primary"
							></Box>
						</Box>
					</Tooltip>
				) : null}
			</Box>
			<Box>
				{allOptions.length > 1 ? (
					<Text fontSize="sm">
						{pluralize("Option", allOptions.length, true)}
					</Text>
				) : null}
				<Box>
					{allOptions.map(({ given_quote, id }, index) =>
						given_quote ? (
							<Box
								key={id}
								fontSize={
									index > 0 ? "lg" : converted || latestGiven ? "3xl" : "xl"
								}
								fontWeight="semibold"
								title="Package Price"
								color={
									converted ? "success" : latestGiven ? "primary" : "muted"
								}
							>
								{index > 0 ? <Text as="span">/ </Text> : null}
								<Money
									currency={given_quote.given_currency}
									amount={given_quote.given_price}
								/>
							</Box>
						) : null
					)}
				</Box>
				{!given_quote ? (
					<Box color="warning" fontWeight="semibold">
						<Icons.Attention /> Price Not Set
					</Box>
				) : null}
			</Box>
			<Box fontSize="sm">
				{joinAttributes(
					<Time
						value={localOrUtcTimestampToLocalDate(
							quote.start_date_local,
							quote.start_date
						)}
						format="D MMM"
					/>,
					<TimeDuration value={createDuration(quote.days, "days")}>
						{quote.days}D
					</TimeDuration>,
					<Box
						display="inline"
						title={`${quote.no_of_adults} Adults and ${quote.no_of_children} Children`}
					>
						{quote.no_of_adults}A
						{quote.no_of_children > 0 ? `,${quote.no_of_children}C` : ""}
					</Box>
				)}
			</Box>
			<RelativeTime
				value={utcTimestampToLocalDate((given_quote || quote).created_at)}
				color="muted"
				fontSize="xs"
				display="block"
			/>
			{shared_by_tenant ? (
				<Box marginTop="2">
					<Badge title={`Received from ${shared_by_tenant.name}`}>
						<Icons.ChevronDown rotate="270" /> {shared_by_tenant.short_name}
					</Badge>
				</Box>
			) : null}
			{shared_with_tenant ? (
				<Box marginTop="2">
					<Badge title={`Sent to ${shared_with_tenant.name}`}>
						<Icons.ChevronDown rotate="90" /> {shared_with_tenant.short_name}
					</Badge>
				</Box>
			) : null}
		</NavLink>
	)
}

export function QuoteRoute({
	quoteId,
	readOnly,
	showHotelBookingStatus,
	canBeConverted,
	canSendToHolding,
	onChange,
}: {
	quoteId: number | string
	readOnly?: boolean
	showHotelBookingStatus?: boolean
	canBeConverted: boolean
	canSendToHolding: boolean
	onChange: () => void
}) {
	return (
		<Container fluid paddingY="4" key={quoteId}>
			<Quote
				quoteId={quoteId}
				readOnly={readOnly}
				showHotelBookingStatus={showHotelBookingStatus}
				canBeConverted={canBeConverted}
				canSendToHolding={canSendToHolding}
				onChange={onChange}
			/>
		</Container>
	)
}

interface IQuoteProps {
	quote?: ITripQuote
	quoteId: number | string
	readOnly?: boolean
	showHotelBookingStatus?: boolean
	canBeConverted?: boolean
	canSendToHolding?: boolean
	hideItineary?: boolean
	onChange?: () => void
	hideOptions?: boolean
}

export function Quote({
	readOnly,
	showHotelBookingStatus,
	quoteId,
	quote: initialQuote,
	canBeConverted,
	canSendToHolding,
	hideItineary,
	onChange,
	hideOptions,
}: IQuoteProps) {
	const xhr = useXHR()
	const {
		data: tripQuote,
		mutate,
		isValidating,
	} = useFetchQuote(quoteId, initialQuote)
	const navigate = useNavigate()
	function revalidate() {
		mutate()
		onChange?.()
	}
	const hasTripHoldingFeature = useHasFeatureFlag("hold_trips")
	const canSharePDF = useHasFeatureFlag("share_quote_pdf")
	const canShareWordDoc = useHasFeatureFlag("share_quote_word_doc")
	const { hasAnyPermission } = useCheckPermissions()
	canSendToHolding = canSendToHolding && hasTripHoldingFeature
	const canEditQuote = true
	const canViewTotalCosting = hasAnyPermission(
		PERMISSIONS.VIEW_QUERIES,
		PERMISSIONS.MANAGE_ACCOUNTING
	)
	const canViewAccommocationCosting = hasAnyPermission(
		PERMISSIONS.VIEW_QUERIES,
		PERMISSIONS.MODIFY_HOTEL_BOOKINGS,
		PERMISSIONS.MANAGE_ACCOUNTING
	)
	const canViewTranportationActivityCosting = hasAnyPermission(
		PERMISSIONS.VIEW_QUERIES,
		PERMISSIONS.MODIFY_CAB_SCHEDULES,
		PERMISSIONS.MANAGE_ACCOUNTING
	)
	const diffFromTrip = useMemo(() => {
		if (!tripQuote?.trip) return null
		return getTripQuoteDetailsDiff(tripQuote.trip, tripQuote.quote)
	}, [tripQuote])
	const flightsPriceInGivenCurrency = useMemo(() => {
		if (!tripQuote || !tripQuote.given_quote) return null
		const flights = tripQuote.quote.flights
		if (!flights.length) return null
		const currency_pairs = tripQuote.given_quote.currency_pairs
		const costingCurrency = tripQuote.currency
		const flightsGivenPrice = (flights || []).reduce<Array<TMoney>>(
			(total, { given_price, currency }) => {
				return addMoneyFromDifferentCurrencies(
					total.concat(moneyParseByDecimal(given_price || 0, currency))
				)
			},
			[createMoney(0, costingCurrency)]
		)
		const convert = makeCurrencyConverter(currency_pairs)
		const flightsPriceInCostingCurrency = convert(
			costingCurrency,
			flightsGivenPrice
		)
		return convert(
			tripQuote.given_quote.given_currency,
			flightsPriceInCostingCurrency
		)
	}, [tripQuote])
	if (!tripQuote || !tripQuote.quote) {
		return <Spinner alignCenter padding="4" />
	}
	const {
		id,
		quote,
		trip_id,
		itinerary,
		shared_with_tenant,
		shared_by_tenant,
		created_for_quote_request,
		converted_shared_quote,
		is_latest,
		options,
		given_quote,
	} = tripQuote
	const {
		start_date,
		start_date_local,
		days,
		cabs = [],
		used_cab_types,
		cabs_price_calculations,
		travel_activities = [],
		transport_extras = [],
		other_extras = [],
		flights,
		comments,
		created_by,
		created_at,
		cabs_tax_percentage,
	} = quote
	readOnly =
		readOnly ||
		!canEditQuote ||
		((
			hasTripHoldingFeature
				? canBeConverted && canSendToHolding
				: canBeConverted
		)
			? false
			: !is_latest)
	const allOptions = [tripQuote].concat(hideOptions ? [] : options || [])

	const timezoneDiff = getDiffBetweenLocalAndUtcTimestamp(
		start_date_local,
		start_date
	)
	return (
		<Box cursor={isValidating ? "wait" : undefined}>
			<Stack gap="4">
				{timezoneDiff ? (
					<Alert status="warning">
						The Timezone of this quote differs from your local timezone by{" "}
						{timezoneDiff} mins. Please avoid modifying details.
					</Alert>
				) : null}
				{tripQuote?.is_latest &&
				!readOnly &&
				(tripQuote.used_for_holding ||
					tripQuote.created_after_holding ||
					tripQuote.created_after_conversion ||
					tripQuote.used_for_conversion) ? (
					<LatestQuoteDiffFromBookingsForTrip tripId={tripQuote.trip_id} />
				) : null}
				<Inline
					justifyContent="between"
					gap="4"
					collapseBelow="md"
					flexWrap="wrap"
				>
					<Stack as="header" gap="4">
						<Inline gap="4" alignItems="center">
							<Heading fontSize="base" as="h5" color="muted">
								Package Quote Price
							</Heading>
							{!readOnly &&
							!shared_with_tenant &&
							!given_quote?.used_for_conversion &&
							!given_quote?.used_for_holding ? (
								<Component initialState={false}>
									{({ state, setState }) => (
										<>
											<Button
												onClick={() => setState(true)}
												size="sm"
												level={!given_quote ? "primary" : "tertiary"}
												status={!given_quote ? "danger" : undefined}
											>
												<Icons.Pencil />
											</Button>
											<Dialog
												open={state}
												onClose={() => setState(false)}
												title="Edit Selling Price"
												lg
											>
												<Dialog.Body>
													<GiveQuote
														tripQuote={tripQuote}
														onChange={() => {
															setState(false)
															revalidate()
															setTimeout(() => {
																navigate(`/trips/:tripId`, {
																	params: { tripId: String(trip_id) },
																	replace: true,
																})
															}, 1000)
														}}
														onCancel={() => setState(false)}
													/>
												</Dialog.Body>
											</Dialog>
										</>
									)}
								</Component>
							) : null}
						</Inline>
						<Inline gap="6" flexWrap="wrap">
							{allOptions.map((tripQuote, index, tripQuotes) => {
								const { given_quote } = tripQuote
								return (
									<Box
										key={tripQuote.id}
										overflow="hidden"
										position="relative"
										{...(given_quote?.used_for_holding ||
										given_quote?.used_for_conversion
											? {
													paddingX: "4",
													paddingTop: "8",
													paddingBottom: "4",
													borderWidth: "2",
													rounded: "md",
													borderColor: given_quote?.used_for_conversion
														? "success_emphasis"
														: "warning_emphasis",
												}
											: {})}
									>
										{given_quote?.used_for_conversion ||
										given_quote?.used_for_holding ? (
											<Box
												position="absolute"
												left="0"
												right="0"
												width="full"
												top="0"
												paddingY="1"
												paddingX="4"
												bgColor={
													given_quote?.used_for_conversion
														? "success"
														: "warning"
												}
												fontSize="sm"
												fontWeight="semibold"
											>
												{given_quote?.used_for_conversion
													? "Used for Conversion"
													: "Used for Holding"}
											</Box>
										) : null}
										<Stack gap="2">
											{tripQuotes.length > 1 ? (
												<Box>
													<Box
														display="inlineBlock"
														borderBottomWidth="2"
														borderColor="emphasis"
													>
														<Text fontWeight="semibold">
															Option {index + 1}: {tripQuote.name}
														</Text>
													</Box>
												</Box>
											) : null}
											<Inline gap="4">
												<Inline gap="3" alignItems="baseline" flexWrap="wrap">
													<Box>
														{given_quote ? (
															<GivenPackagePriceDetailsInDialog
																tripQuotes={tripQuotes}
															>
																{({ show }) => (
																	<Button
																		onClick={
																			canViewTotalCosting ? show : undefined
																		}
																		disabled={!canViewTotalCosting}
																		inline
																	>
																		<Money
																			currency={given_quote.given_currency}
																			amount={given_quote.given_price}
																			color="accent"
																			showCurrency
																			fontSize="xl"
																			fontWeight="semibold"
																		/>
																		<Box
																			as="span"
																			marginLeft="1"
																			color="default"
																		>
																			(
																			{!given_quote.tax_type.display_name
																				? `Tax: `
																				: given_quote.gst_included
																					? `inc.${
																							given_quote.tax_percentage
																								? ` ${given_quote.tax_percentage}%`
																								: ``
																						}`
																					: "exc."}{" "}
																			{given_quote.tax_name})
																		</Box>
																	</Button>
																)}
															</GivenPackagePriceDetailsInDialog>
														) : (
															<Text
																color="warning"
																fontSize="xl"
																fontWeight="semibold"
															>
																Not Set
															</Text>
														)}
													</Box>
													{canViewTotalCosting ? (
														<Inline gap="3" alignItems="center">
															<Text fontSize="xl" color="muted">
																/
															</Text>
															<Inline alignItems="baseline" gap="1">
																<Money
																	currency={tripQuote.currency}
																	amount={tripQuote.cost_price}
																	showCurrency
																	fontSize="md"
																/>
																<Text fontSize="sm" color="muted">
																	(cost price)
																</Text>
															</Inline>
														</Inline>
													) : null}
												</Inline>
											</Inline>
											{given_quote &&
											given_quote.given_prices_per_person?.length ? (
												<Box>
													<table>
														<tbody>
															{given_quote.given_prices_per_person.map(
																(
																	{
																		price,
																		currency,
																		per_pax_label,
																		total_pax_label,
																	},
																	index
																) => (
																	<tr key={index}>
																		<Box as="td" textAlign="right">
																			<Money
																				currency={currency}
																				amount={Number(price)}
																			/>
																		</Box>
																		<td>
																			&nbsp;/&nbsp;{per_pax_label} x{" "}
																			{total_pax_label}
																		</td>
																	</tr>
																)
															)}
															{flightsPriceInGivenCurrency ? (
																<tr>
																	<Box as="td" textAlign="right">
																		<Money
																			money={flightsPriceInGivenCurrency}
																		/>
																	</Box>
																	<td>
																		&nbsp;&nbsp;
																		<Icons.Airplane /> Flights
																	</td>
																</tr>
															) : null}
														</tbody>
													</table>
												</Box>
											) : null}
											{given_quote?.comments ? (
												<blockquote>{given_quote.comments}</blockquote>
											) : null}
											{index === 0 && comments ? (
												<blockquote>{comments}</blockquote>
											) : null}
										</Stack>
									</Box>
								)
							})}
						</Inline>
						<Stack gap="2">
							<Box fontSize="sm" color="muted">
								{joinAttributes(
									given_quote &&
										getDiff(
											utcTimestampToLocalDate(given_quote.created_at),
											utcTimestampToLocalDate(created_at),
											"minutes"
										) > 1 ? (
										<>
											Rates updated{" "}
											<RelativeTime
												value={utcTimestampToLocalDate(given_quote.created_at)}
											/>{" "}
											by {given_quote.created_by.name}
										</>
									) : null,
									<>
										Created{" "}
										<RelativeTime value={utcTimestampToLocalDate(created_at)} />{" "}
										by {created_by.name}
									</>
								)}
							</Box>
							<Inline gap="2" flexWrap="wrap">
								{tripQuote.created_after_conversion ? (
									<Box>
										<Badge primary>After Conversion Quote</Badge>
									</Box>
								) : !tripQuote.used_for_conversion &&
								  tripQuote.created_after_holding ? (
									<Box>
										<Badge warning>After Holding Quote</Badge>
									</Box>
								) : null}
								{shared_by_tenant ? (
									<Box>
										<Badge success>
											Received from{" "}
											{shared_by_tenant.short_name || shared_by_tenant.name}
										</Badge>
									</Box>
								) : null}
							</Inline>
							{given_quote ? (
								<Stack gap="2">
									{is_latest ? (
										<Box>
											<Badge>Latest Quote</Badge>
										</Box>
									) : null}
									{shared_with_tenant ? (
										<Box>
											<Badge success>
												Sent to{" "}
												{shared_with_tenant.short_name ||
													shared_with_tenant.name}
											</Badge>
										</Box>
									) : created_for_quote_request && given_quote ? (
										<Box>
											<Button
												level="primary"
												onClick={async () => {
													if (
														window.confirm(
															`Are you sure you want to share it with ${created_for_quote_request.from.short_name}. You will NOT be able to edit this quote, selling price and itinerary after sharing. But you can always create a new quote with changes and share.`
														)
													) {
														try {
															await xhr.post("/share-trip-quotes", {
																trip_quote_id: id,
																comments: "Custom comments",
															})
														} catch (e) {
															const error = e as Error
															window.alert(error.message)
														}
														revalidate()
													}
												}}
											>
												Send to {created_for_quote_request.from.short_name}
											</Button>
										</Box>
									) : null}
									{!readOnly &&
									canBeConverted &&
									(!created_for_quote_request || converted_shared_quote) ? (
										<Box>
											<ButtonLink
												to={
													generatePath("/trips/:tripId/convert", {
														tripId: trip_id.toString(),
													}) + queryToSearch({ using: id })
												}
												data-testid="convert_quote_btn"
												level="secondary"
												status="primary"
												size="sm"
											>
												{
													/* Trip Holding is disabled for B2B on Sembark Queries */ shared_by_tenant ||
													!canSendToHolding ||
													created_for_quote_request
														? "Convert using Quote"
														: "Convert/On-Hold using Quote"
												}
											</ButtonLink>
										</Box>
									) : null}
								</Stack>
							) : null}
						</Stack>
					</Stack>
					<Box textAlign="right" paddingY="2" marginLeft="auto">
						<Inline gap="2" justifyContent="end" alignItems="center">
							{!readOnly && !tripQuote.readonly ? (
								<Box>
									<ButtonLink
										level="primary"
										to={
											generatePath("/trips/:tripId/new-quote", {
												tripId: String(tripQuote.trip_id),
											}) +
											`${queryToSearch({
												...getBasicInfoForQueryFromTrip(
													tripQuote.quote,
													tripQuote.id
												),
												editing: 1,
												afterConfirmedQuote:
													tripQuote.used_for_holding ||
													tripQuote.used_for_conversion ||
													tripQuote.created_after_holding ||
													tripQuote.created_after_conversion
														? 1
														: 0,
											})}`
										}
									>
										Edit Quote
									</ButtonLink>
								</Box>
							) : null}
							<ShareQuoteInDialog
								quote={tripQuote}
								isValidating={isValidating}
							/>
							{itinerary &&
							(canShareWordDoc || canSharePDF) &&
							((itinerary.created_from_quote_itinerary &&
								shared_by_tenant &&
								itinerary.created_from_quote_itinerary.file_url) ||
								tripQuote.given_quote) ? (
								<Dropdown alignRight>
									<Dropdown.ToggleButton level="tertiary" size="sm">
										<Icons.DotsVertical />
									</Dropdown.ToggleButton>
									<Dropdown.Menu>
										{itinerary && tripQuote.given_quote && canSharePDF ? (
											<GeneratedItineraryAsset
												id={itinerary.id}
												isGenerating={itinerary.is_generating}
												fileUrl={itinerary.file_url}
												onChange={() => revalidate()}
												level="tertiary"
												downloadLabel={
													<>
														<Icons.DocumentDownload /> Download PDF
													</>
												}
											/>
										) : null}
										{itinerary.created_from_quote_itinerary &&
										shared_by_tenant &&
										itinerary.created_from_quote_itinerary.file_url &&
										canSharePDF ? (
											<GeneratedItineraryAsset
												id={itinerary.created_from_quote_itinerary.id}
												isGenerating={false}
												fileUrl={
													itinerary.created_from_quote_itinerary.file_url
												}
												onChange={() => revalidate()}
												title={`Download quote shared by ${shared_by_tenant.short_name}`}
												downloadLabel={
													<>
														<Icons.DocumentDownload /> Download Received Quote
													</>
												}
											/>
										) : null}
										{tripQuote.given_quote ? (
											<>
												{itinerary.word_doc_file_url &&
												!itinerary.is_generating &&
												canShareWordDoc ? (
													<GeneratedItineraryAsset
														id={itinerary.id}
														onChange={() => revalidate()}
														fileUrl={itinerary.word_doc_file_url}
														regenerateLabel={
															<Box display="inline">
																<Icons.Refresh /> Regenerate Files
															</Box>
														}
														downloadLabel={
															<>
																<Icons.DocumentDownload /> Download Word Docx
															</>
														}
														target={undefined}
														download
													/>
												) : null}
												{canSharePDF || canShareWordDoc ? (
													<GeneratedItineraryAsset
														id={itinerary.id}
														isGenerating={itinerary.is_generating}
														onChange={() => revalidate()}
														fileUrl={undefined}
														regenerateLabel={
															<Box display="inline">
																<Icons.Refresh /> Regenerate Files
															</Box>
														}
													/>
												) : null}
											</>
										) : null}
									</Dropdown.Menu>
								</Dropdown>
							) : null}
						</Inline>
					</Box>
				</Inline>
			</Stack>
			<Stack borderWidth="1" padding="2" rounded="md" gap="2" marginY="4">
				<Box>
					{joinAttributes(
						<Box as="span">
							<Box display="inlineBlock" marginRight="2">
								<Icons.Calendar color="muted" />
							</Box>
							<Time
								value={localOrUtcTimestampToLocalDate(
									start_date_local,
									start_date
								)}
							/>{" "}
							for{" "}
							<TimeDuration value={createDuration(days, "days")}>
								{pluralize("Day", days, true)}
							</TimeDuration>
						</Box>,
						<Box as="span">
							<Box display="inlineBlock" marginRight="2">
								<Icons.UserGroup color="muted" />
							</Box>
							{quote.pax_string}
						</Box>
					)}
				</Box>
				{diffFromTrip ? (
					<Box color="warning" fontSize="sm" title={diffFromTrip.message}>
						<Icons.AttentionSolid /> The quote has different basic details than
						trip
					</Box>
				) : null}
			</Stack>
			<Stack position="relative" gap="6">
				<Heading as="h4">Services</Heading>
				{allOptions.map((tripQuote, index, arr) => {
					const { quote } = tripQuote
					const { hotels, hotel_extras, start_date } = quote
					return (
						<Stack key={tripQuote.id} gap="2">
							{arr.length > 1 ? (
								<Box>
									<Box
										display="inlineBlock"
										bgColor="emphasis"
										color="on_emphasis"
										padding="2"
										rounded="md"
									>
										<Heading fontSize="md">
											Option {index + 1}: {tripQuote.name}
										</Heading>
									</Box>
								</Box>
							) : null}
							<QuoteHotels
								hotels={hotels}
								hotelExtras={hotel_extras}
								showHotelBookingStatus={showHotelBookingStatus}
								hidePrice={tripQuote.readonly || !canViewAccommocationCosting}
								startDateUTC={start_date}
							/>
						</Stack>
					)
				})}
				<QuoteTransportsAndActivityTickets
					transports={cabs}
					usedCabTypes={used_cab_types}
					cabsPriceCalculations={cabs_price_calculations}
					transportExtras={transport_extras}
					travelActivities={travel_activities}
					hidePrice={tripQuote.readonly || !canViewTranportationActivityCosting}
					startDateUTC={start_date}
					taxPercentage={cabs_tax_percentage}
				/>
				<QuoteFlights
					flights={flights}
					hidePrice={tripQuote.readonly || !canViewTotalCosting}
					startDateUTC={start_date}
				/>
				<QuoteExtras
					extras={other_extras}
					hidePrice={tripQuote.readonly || !canViewTotalCosting}
					startDateUTC={start_date}
				/>
			</Stack>
			{!hideItineary ? (
				<>
					<Divider />
					<ItineraryItem
						quote={tripQuote}
						editable={!readOnly && !shared_with_tenant}
						onChange={() => revalidate()}
					/>
				</>
			) : null}
		</Box>
	)
}

export function QuoteHotels({
	hotels,
	hotelExtras,
	showHotelBookingStatus,
	hidePrice,
	startDateUTC,
}: {
	hotels:
		| undefined
		| Array<IQuote["hotels"][number] & { fetching_prices?: boolean | 0 | 1 }>
	hotelExtras: $PropertyType<IQuote, "hotel_extras"> | undefined
	hidePrice?: boolean
	showHotelBookingStatus?: boolean
	startDateUTC: string
}) {
	const [totalGivenPrice] = useMemo(() => {
		return (
			[] as Array<{
				given_price?: number
				currency: string
				calculated_price?: number
				similar_hotel_options?: Array<{
					calculated_price?: number
					given_price?: number
				}>
			}>
		)
			.concat(hotels || [])
			.concat(hotelExtras || [])
			.reduce<[Array<TMoney>, Array<TMoney>]>(
				(
					[totalGivenPrice, totalCalculatedPrice],
					{ given_price, calculated_price, similar_hotel_options, currency }
				) => {
					const [given, calculated] = similar_hotel_options?.length
						? similar_hotel_options.reduce<[number, number | undefined]>(
								([maxPrice, rateOfMaxOne], h) => {
									const max = Math.max(maxPrice, h.given_price || 0)
									return [
										max,
										max > maxPrice ? h.calculated_price : rateOfMaxOne,
									]
								},
								[given_price || 0, calculated_price]
							)
						: [given_price, calculated_price]
					return [
						addMoneyFromDifferentCurrencies(
							totalGivenPrice.concat([
								moneyParseByDecimal(given || 0, currency),
							])
						),
						addMoneyFromDifferentCurrencies(
							totalCalculatedPrice.concat([
								moneyParseByDecimal(calculated || 0, currency),
							])
						),
					]
				},
				[[], []]
			)
	}, [hotels, hotelExtras])
	return hotels?.length || hotelExtras?.length ? (
		<Box as="section">
			<Box display="flex" overflow="auto">
				<Box display={{ xs: "none", sm: "block" }}>
					<SectionIcon>
						<Icons.Bed size="6" />
					</SectionIcon>
				</Box>
				<Stack gap="4" width="full" flex="1" minWidth="0">
					{hotels?.length ? (
						<Box>
							<Box display="flex" marginBottom="2" alignItems="center">
								<Box display={{ xs: "block", sm: "none" }} marginRight="2">
									<SectionIcon>
										<Icons.Bed size="6" />
									</SectionIcon>
								</Box>
								<Heading as="h5" fontSize="md">
									Accommodation
								</Heading>
							</Box>
							<Box display={{ xs: "block", sm: "none" }}>
								<Box as="ol" marginBottom="8">
									{hotels.map((quoteHotel) => {
										const {
											hotel,
											date,
											date_local,
											meal_plan,
											room_type,
											no_of_rooms,
											persons_per_room,
											comments,
											currency,
											fetching_prices,
											calculated_price,
											given_price,
											adults_with_extra_bed,
											children_with_extra_bed,
											children_without_extra_bed,
											similar_hotel_options,
											room_configuration,
										} = quoteHotel
										const [
											maxPriceWithOptions,
											maxCalculatedPriceWithOptions,
										]: [number, number | undefined] =
											similar_hotel_options?.length
												? similar_hotel_options.reduce<
														[number, number | undefined]
													>(
														([maxPrice, rateOfMaxOne], h) => {
															const max = Math.max(maxPrice, h.given_price)
															return [
																max,
																max > maxPrice
																	? h.calculated_price
																	: rateOfMaxOne,
															]
														},
														[given_price, calculated_price]
													)
												: [given_price, calculated_price]
										const isUsingSystemRates =
											Number(maxPriceWithOptions) &&
											Number(maxPriceWithOptions) ===
												Number(maxCalculatedPriceWithOptions)
										return (
											<Box
												as="li"
												key={quoteHotel.id}
												marginBottom="4"
												rounded="lg"
												overflow="hidden"
												borderWidth="1"
											>
												<Inline padding="4" bgColor="subtle" gap="4">
													<Stack style={{ width: "36px" }}>
														<Text fontWeight="semibold">
															{withOrdinalSuffix(
																getDiff(
																	utcTimestampToLocalDate(date),
																	utcTimestampToLocalDate(startDateUTC),
																	"day"
																) + 1
															)}{" "}
														</Text>
														<Text color="muted" fontSize="sm">
															Night
														</Text>
													</Stack>
													<Stack flex="1" minWidth="0" gap="1">
														<Stack>
															<Text fontWeight="semibold">{hotel.name}</Text>
															<Text fontSize="sm">
																{joinAttributes(
																	hotel.location.short_name,
																	hotel.stars ? (
																		<Stars stars={hotel.stars} />
																	) : null,
																	hotel.deleted_at ? (
																		<Badge warning>Disabled</Badge>
																	) : null
																)}
															</Text>
														</Stack>
														{similar_hotel_options?.length ? (
															<Box fontSize="sm">
																{similar_hotel_options?.map(
																	(similarQuoteHotel) => (
																		<Box
																			key={similarQuoteHotel.hotel.id}
																			paddingLeft="4"
																			position="relative"
																		>
																			<Box position="absolute" left="0">
																				/
																			</Box>
																			<Box fontWeight="semibold">
																				{similarQuoteHotel.hotel.name}
																				{similarQuoteHotel.hotel
																					.stars_string ? (
																					<Box
																						as="span"
																						fontSize="xs"
																						marginLeft="1"
																					>
																						{
																							similarQuoteHotel.hotel
																								.stars_string
																						}
																					</Box>
																				) : null}
																				{similarQuoteHotel.hotel.deleted_at ? (
																					<Badge warning>Disabled</Badge>
																				) : null}
																			</Box>
																			<Box fontSize="xs">
																				{joinAttributes(
																					similarQuoteHotel.hotel.location
																						.name !== hotel.location.name
																						? similarQuoteHotel.hotel.location
																								.short_name
																						: null,
																					similarQuoteHotel.meal_plan.id !==
																						meal_plan.id ? (
																						<>
																							{similarQuoteHotel.meal_plan.name}
																						</>
																					) : null,
																					similarQuoteHotel.room_type.id !==
																						room_type.id ||
																						similarQuoteHotel.no_of_rooms !==
																							no_of_rooms ||
																						similarQuoteHotel.persons_per_room !==
																							persons_per_room ? (
																						<>
																							{similarQuoteHotel.no_of_rooms}{" "}
																							{similarQuoteHotel.room_type.name}{" "}
																							(
																							{joinAttributes(
																								`${
																									similarQuoteHotel.no_of_rooms *
																									similarQuoteHotel.persons_per_room
																								}A`,
																								[
																									similarQuoteHotel.adults_with_extra_bed,
																									`${similarQuoteHotel.adults_with_extra_bed} AwEb`,
																								],
																								[
																									similarQuoteHotel.children_with_extra_bed,
																									`${similarQuoteHotel.children_with_extra_bed} CwEb`,
																								],
																								[
																									similarQuoteHotel.children_without_extra_bed,
																									`${similarQuoteHotel.children_without_extra_bed} CNB`,
																								]
																							)}
																							)
																						</>
																					) : null
																				)}
																			</Box>
																		</Box>
																	)
																)}
															</Box>
														) : null}
													</Stack>
												</Inline>
												<Inline
													paddingX="4"
													paddingY="2"
													bgColor="default"
													gap="4"
												>
													<Box style={{ width: "36px" }}>
														<Time
															timestamp={date}
															localTimestamp={date_local}
															format="D MMM"
															fontSize="sm"
															whiteSpace="preserve"
															fontWeight="semibold"
														/>
													</Box>
													<Stack flex="1" gap="1">
														<Inline gap="2">
															<Box>
																<Icons.Bed color="muted" />
															</Box>
															<Text fontWeight="semibold">
																{joinAttributes(
																	meal_plan.name,
																	`${no_of_rooms} ${room_type.name}`
																)}
															</Text>
														</Inline>
														<Inline justifyContent="between" gap="2">
															<Inline flex="1" alignItems="center" gap="2">
																<Box>
																	<Icons.UserGroup color="muted" />
																</Box>
																<Box>
																	<Text>
																		{room_configuration?.description_short ||
																			[
																				persons_per_room * no_of_rooms + " Pax",
																				adults_with_extra_bed
																					? `${adults_with_extra_bed} AwEb`
																					: "",
																				children_with_extra_bed
																					? `${children_with_extra_bed} CwEb`
																					: "",
																				children_without_extra_bed
																					? `${children_without_extra_bed} CNB`
																					: "",
																			]
																				.filter(Boolean)
																				.join(" + ")}
																	</Text>
																</Box>
															</Inline>
															{!hidePrice ? (
																<Inline alignItems="end">
																	<Box>
																		<Inline
																			gap="1"
																			alignItems="center"
																			fontWeight="semibold"
																		>
																			{isUsingSystemRates ? (
																				<Icons.Ok
																					color="success"
																					title="Using System Rates"
																				/>
																			) : null}
																			<Money
																				amount={maxPriceWithOptions}
																				currency={currency}
																				showCurrency
																			/>
																		</Inline>
																	</Box>
																	{isUsingSystemRates ? null : (
																		<Text
																			fontSize="xs"
																			color="muted"
																			title="System Rate"
																		>
																			{" / "}
																			{maxCalculatedPriceWithOptions ? (
																				<Money
																					amount={maxPriceWithOptions}
																					currency={currency}
																				/>
																			) : (
																				"N/A"
																			)}
																		</Text>
																	)}
																	{fetching_prices ? (
																		<Text>
																			<Badge warning>
																				<Icons.Refresh spin size="3" /> Rates
																			</Badge>
																		</Text>
																	) : null}
																</Inline>
															) : null}
														</Inline>
														{comments ? (
															<Inline
																alignItems="center"
																gap="2"
																title="Comments"
															>
																<Box>
																	<Icons.Annotation color="muted" />
																</Box>
																<Text fontSize="sm" color="muted">
																	{comments}
																</Text>
															</Inline>
														) : null}
													</Stack>
												</Inline>
											</Box>
										)
									})}
								</Box>
							</Box>
							<Box display={{ xs: "none", sm: "block" }}>
								<Table
									bordered
									responsive
									headers={["Night", "Hotel", "Meal", "Rooms"]
										.concat(hidePrice ? [] : ["Price"])
										.concat(showHotelBookingStatus ? ["Booking Stage"] : [])}
									alignCols={!hidePrice ? { 4: "right" } : {}}
									rows={hotels.map((quoteHotel) => {
										const {
											hotel,
											date,
											date_local,
											meal_plan,
											room_type,
											no_of_rooms,
											persons_per_room,
											comments,
											currency,
											fetching_prices,
											calculated_price,
											given_price,
											latest_booking_stage,
											adults_with_extra_bed,
											children_with_extra_bed,
											children_without_extra_bed,
											similar_hotel_options,
											room_configuration,
											per_room_given_price,
											per_adult_with_extra_bed_given_price,
											per_child_with_extra_bed_given_price,
											per_child_without_extra_bed_given_price,
										} = quoteHotel
										const [
											maxPriceWithOptions,
											maxCalculatedPriceWithOptions,
											maxPriceBirfucationWithOptions,
										] = similar_hotel_options?.length
											? similar_hotel_options.reduce<
													[
														number,
														number | undefined,
														{
															per_room_given_price?: number
															per_adult_with_extra_bed_given_price?: number
															per_child_with_extra_bed_given_price?: number
															per_child_without_extra_bed_given_price?: number
														},
													]
												>(
													(
														[maxPrice, rateOfMaxOne, priceBirfucationOfMaxOne],
														h
													) => {
														const max = Math.max(maxPrice, h.given_price)
														const {
															per_room_given_price,
															per_adult_with_extra_bed_given_price,
															per_child_with_extra_bed_given_price,
															per_child_without_extra_bed_given_price,
														} = h
														return [
															max,
															max > maxPrice
																? h.calculated_price
																: rateOfMaxOne,
															max > maxPrice
																? {
																		per_room_given_price,
																		per_adult_with_extra_bed_given_price,
																		per_child_with_extra_bed_given_price,
																		per_child_without_extra_bed_given_price,
																	}
																: priceBirfucationOfMaxOne,
														]
													},
													[
														given_price,
														calculated_price,
														{
															per_room_given_price,
															per_adult_with_extra_bed_given_price,
															per_child_with_extra_bed_given_price,
															per_child_without_extra_bed_given_price,
														},
													]
												)
											: [
													given_price,
													calculated_price,
													{
														per_room_given_price,
														per_adult_with_extra_bed_given_price,
														per_child_with_extra_bed_given_price,
														per_child_without_extra_bed_given_price,
													},
												]
										const isUsingSystemRates =
											Number(maxPriceWithOptions) &&
											Number(maxPriceWithOptions) ===
												Number(maxCalculatedPriceWithOptions)
										return [
											<Stack>
												<Text fontWeight="semibold">
													{withOrdinalSuffix(
														getDiff(
															utcTimestampToLocalDate(date),
															utcTimestampToLocalDate(startDateUTC),
															"day"
														) + 1
													)}
												</Text>
												<Text>
													<Time
														timestamp={date}
														localTimestamp={date_local}
														format="D MMM"
														whiteSpace="preserve"
														fontSize="sm"
														color="muted"
													/>
												</Text>
											</Stack>,
											<Stack gap="1">
												<Stack gap="px">
													<Box fontWeight="semibold">{hotel.name}</Box>
													<Box fontSize="sm">
														{hotel.location.short_name}
														{hotel.stars_string
															? `, ${hotel.stars_string}`
															: null}
														{hotel.deleted_at ? (
															<Badge warning>Disabled</Badge>
														) : null}
													</Box>
												</Stack>
												{similar_hotel_options?.length ? (
													<Box fontSize="sm">
														{similar_hotel_options?.map((similarQuoteHotel) => (
															<Box
																key={similarQuoteHotel.hotel.id}
																position="relative"
																paddingLeft="4"
																marginTop="1"
															>
																<Box position="absolute" left="0">
																	/
																</Box>
																<Box fontWeight="semibold">
																	{similarQuoteHotel.hotel.name}{" "}
																	{similarQuoteHotel.hotel.stars_string ? (
																		<Box as="span" fontSize="xs" marginLeft="1">
																			{similarQuoteHotel.hotel.stars_string}
																		</Box>
																	) : null}{" "}
																	{similarQuoteHotel.hotel.deleted_at ? (
																		<Box marginLeft="1">
																			<Badge warning>Disabled</Badge>
																		</Box>
																	) : null}
																</Box>
																<Box fontSize="xs">
																	{joinAttributes(
																		similarQuoteHotel.hotel.location.name !==
																			hotel.location.name
																			? similarQuoteHotel.hotel.location
																					.short_name
																			: null,
																		similarQuoteHotel.meal_plan.id !==
																			meal_plan.id ? (
																			<>{similarQuoteHotel.meal_plan.name}</>
																		) : null,
																		similarQuoteHotel.room_type.id !==
																			room_type.id ||
																			similarQuoteHotel.no_of_rooms !==
																				no_of_rooms ||
																			similarQuoteHotel.persons_per_room !==
																				persons_per_room ? (
																			<>
																				{similarQuoteHotel.no_of_rooms}{" "}
																				{similarQuoteHotel.room_type.name} (
																				{joinAttributes(
																					`${
																						similarQuoteHotel.no_of_rooms *
																						similarQuoteHotel.persons_per_room
																					}A`,
																					[
																						similarQuoteHotel.adults_with_extra_bed,
																						`${similarQuoteHotel.adults_with_extra_bed} AwEb`,
																					],
																					[
																						similarQuoteHotel.children_with_extra_bed,
																						`${similarQuoteHotel.children_with_extra_bed} CwEb`,
																					],
																					[
																						similarQuoteHotel.children_without_extra_bed,
																						`${similarQuoteHotel.children_without_extra_bed} CNB`,
																					]
																				)}
																				)
																			</>
																		) : null
																	)}
																</Box>
															</Box>
														))}
													</Box>
												) : null}
												{comments ? (
													<Box
														maxWidth="lg"
														color="muted"
														fontSize="sm"
														title="Comment"
													>
														<Icons.Annotation opacity="50" size="4" />{" "}
														{comments}
													</Box>
												) : null}
											</Stack>,
											meal_plan.name,
											<Stack gap="px">
												<Text
													fontWeight="semibold"
													title={`${persons_per_room} Persons per Room`}
												>
													{no_of_rooms} {room_type.name}
												</Text>
												<Text fontSize="sm" color="muted">
													{room_configuration?.description_short ||
														[
															`${persons_per_room * no_of_rooms} Pax`,
															adults_with_extra_bed
																? `${adults_with_extra_bed} AwEB`
																: "",
															children_with_extra_bed
																? `${children_with_extra_bed} CwEB`
																: "",
															children_without_extra_bed
																? `${children_without_extra_bed} CNB`
																: "",
														]
															.filter(Boolean)
															.join(" + ")}
												</Text>
											</Stack>,
										]
											.concat(
												hidePrice
													? []
													: [
															<Stack alignItems="end" gap="1">
																<Inline
																	gap="1"
																	alignItems="center"
																	fontWeight="semibold"
																>
																	{isUsingSystemRates ? (
																		<Icons.Ok
																			color="success"
																			title="Using System Rates"
																		/>
																	) : null}
																	<Money
																		amount={maxPriceWithOptions}
																		currency={currency}
																		showCurrency
																		title={(function getPriceBirfucationAsText() {
																			const parts = [
																				`${formatMoneyByIntl(
																					moneyParseByDecimal(
																						maxPriceBirfucationWithOptions.per_room_given_price ||
																							0,
																						currency
																					)
																				)} x ${no_of_rooms} Room`,
																				adults_with_extra_bed
																					? `${formatMoneyByIntl(
																							moneyParseByDecimal(
																								maxPriceBirfucationWithOptions.per_adult_with_extra_bed_given_price ||
																									0,
																								currency
																							)
																						)} x ${adults_with_extra_bed} AwEb`
																					: "",
																				children_with_extra_bed
																					? `${formatMoneyByIntl(
																							moneyParseByDecimal(
																								maxPriceBirfucationWithOptions.per_child_with_extra_bed_given_price ||
																									0,
																								currency
																							)
																						)} x ${children_with_extra_bed} CwEb`
																					: "",
																				children_without_extra_bed
																					? `${formatMoneyByIntl(
																							moneyParseByDecimal(
																								maxPriceBirfucationWithOptions.per_child_without_extra_bed_given_price ||
																									0,
																								currency
																							)
																						)} x ${children_without_extra_bed} CNB`
																					: "",
																			]
																			return parts.filter(Boolean).join(" +\n")
																		})()}
																	/>
																</Inline>
																{isUsingSystemRates ? null : (
																	<Box
																		fontSize="xs"
																		color="muted"
																		title="System Rate"
																	>
																		/{" "}
																		{maxCalculatedPriceWithOptions ? (
																			<Money
																				amount={maxCalculatedPriceWithOptions}
																				currency={currency}
																			/>
																		) : (
																			"N/A"
																		)}
																	</Box>
																)}
																{fetching_prices ? (
																	<Text>
																		<Badge warning>
																			<Icons.Refresh spin size="3" /> Rates
																		</Badge>
																	</Text>
																) : null}
															</Stack>,
														]
											)
											.concat(
												showHotelBookingStatus
													? [
															<Box>
																{latest_booking_stage
																	? latest_booking_stage.name
																	: "Pending"}
															</Box>,
														]
													: []
											)
									})}
								/>
							</Box>
						</Box>
					) : null}
					{hotelExtras?.length ? (
						<Box>
							<Heading as="h6" fontSize="md">
								Hotel Special Inclusions
							</Heading>
							<Box display={{ xs: "block", sm: "none" }}>
								<Box as="ol" marginBottom="8">
									{hotelExtras.map(
										({
											id,
											service,
											date,
											date_local,
											hotel,
											given_price,
											currency,
											comments,
										}) => (
											<Box
												as="li"
												key={id}
												marginBottom="4"
												rounded="lg"
												overflow="hidden"
												bgColor="default"
												borderWidth="1"
											>
												<Inline padding="4" gap="4">
													{date ? (
														<Stack style={{ width: "36px" }} gap="2">
															<Stack>
																<Text fontWeight="semibold">
																	{withOrdinalSuffix(
																		getDiff(
																			utcTimestampToLocalDate(date),
																			utcTimestampToLocalDate(startDateUTC),
																			"day"
																		) + 1
																	)}
																</Text>
																<Text color="muted" fontSize="sm">
																	Day
																</Text>
															</Stack>
															<Box>
																<Time
																	timestamp={date}
																	localTimestamp={date_local}
																	format="D MMM"
																	fontSize="sm"
																	whiteSpace="preserve"
																	fontWeight="semibold"
																/>
															</Box>
														</Stack>
													) : null}
													<Stack flex="1" minWidth="0" gap="2">
														{hotel ? (
															<Stack>
																<Text fontWeight="semibold">{hotel.name}</Text>
																<Text fontSize="sm">
																	{joinAttributes(
																		hotel.location.short_name,
																		hotel.stars ? (
																			<Stars stars={hotel.stars} />
																		) : null
																	)}
																</Text>
															</Stack>
														) : null}
														<Inline gap="4" justifyContent="between">
															<Text fontWeight="semibold">{service.name}</Text>
															{!hidePrice ? (
																<Money
																	fontWeight="semibold"
																	amount={given_price || 0}
																	currency={currency}
																	showCurrency
																/>
															) : null}
														</Inline>
														{comments ? (
															<Text maxWidth="lg" color="muted" fontSize="sm">
																<Icons.Annotation /> {comments}
															</Text>
														) : null}
													</Stack>
												</Inline>
											</Box>
										)
									)}
								</Box>
							</Box>
							<Box display={{ xs: "none", sm: "block" }}>
								<Table
									bordered
									responsive
									headers={["Night", "Hotel", "Service"].concat(
										hidePrice ? [] : ["Price"]
									)}
									alignCols={{ 3: "right" }}
									rows={hotelExtras.map(
										({
											service,
											date,
											date_local,
											hotel,
											given_price,
											currency,
											comments,
										}) =>
											[
												date ? (
													<Stack>
														<Text fontWeight="semibold">
															{withOrdinalSuffix(
																getDiff(
																	utcTimestampToLocalDate(date),
																	utcTimestampToLocalDate(startDateUTC),
																	"day"
																) + 1
															)}
														</Text>
														<Box>
															<Time
																timestamp={date}
																localTimestamp={date_local}
																format="D MMM"
																whiteSpace="preserve"
																fontSize="sm"
															/>
														</Box>
													</Stack>
												) : null,
												hotel && (
													<Stack>
														<Text fontWeight="semibold">{hotel.name}</Text>
														<Text fontSize="sm" color="muted">
															{hotel.location.short_name}
															{hotel.stars_string
																? `, ${hotel.stars_string}`
																: null}
														</Text>
													</Stack>
												),
												<Box>
													<Box fontWeight="semibold">{service.name}</Box>
													{comments ? (
														<Box as="blockquote" maxWidth="lg">
															{comments}
														</Box>
													) : null}
												</Box>,
											].concat(
												hidePrice
													? []
													: [
															<Money
																fontWeight="semibold"
																amount={given_price || 0}
																currency={currency}
																showCurrency
															/>,
														]
											)
									)}
								/>
							</Box>
						</Box>
					) : null}
				</Stack>
			</Box>
			{!hidePrice ? (
				<Box textAlign="right" marginTop="4">
					<Box
						display="inlineBlock"
						paddingX="4"
						paddingY="1"
						bgColor="subtle"
						borderWidth="1"
						rounded="md"
						fontWeight="semibold"
					>
						Total: <MoneySum money={totalGivenPrice} />
					</Box>
				</Box>
			) : null}
		</Box>
	) : null
}

export function QuoteTransportsAndActivityTickets({
	transports,
	usedCabTypes,
	cabsPriceCalculations,
	transportExtras,
	travelActivities,
	hidePrice,
	startDateUTC,
	taxPercentage,
}: {
	transports: Array<IQuote["cabs"][number] & { fetching_prices?: boolean }>
	usedCabTypes: $PropertyType<IQuote, "used_cab_types">
	cabsPriceCalculations:
		| undefined
		| Array<
				Required<IQuote>["cabs_price_calculations"][number] & {
					fetching_prices?: boolean
				}
		  >
	transportExtras: $PropertyType<IQuote, "transport_extras">
	travelActivities: $PropertyType<IQuote, "travel_activities">
	hidePrice?: boolean
	startDateUTC: string
	taxPercentage?: number
}) {
	const { extraServiceWithoutDates, daywiseServices } = useMemo(() => {
		return getDaywiseTransportsAndActivitiesForQuote({
			transports,
			travelActivities,
			transportExtras,
		})
	}, [transports, travelActivities, transportExtras])

	const [
		totalGivenPrice,
		totalTransportsPrice,
		totalTransportExtrasPrice,
		totalTravelActivitiesPrice,
	] = useMemo(() => {
		function getTotal(
			services: Array<{
				given_price?: number
				currency: string
				calculated_price?: number
			}>
		) {
			return services.reduce<Array<TMoney>>(
				(totalGivenPrice, { given_price, currency }) => {
					return addMoneyFromDifferentCurrencies(
						totalGivenPrice.concat([
							moneyParseByDecimal(given_price || 0, currency),
						])
					)
				},
				[]
			)
		}
		const totalTransportsPrice = getTotal(transports)
		const totalTransportExtrasPrice = getTotal(transportExtras || [])
		const totalTravelActivitiesPrice = getTotal(
			travelActivities.flatMap((t) => t.ticket_tourist_configurations) || []
		)
		return [
			addMoneyFromDifferentCurrencies(
				totalTransportsPrice
					.concat(totalTransportExtrasPrice)
					.concat(totalTravelActivitiesPrice)
			),
			totalTransportsPrice,
			totalTransportExtrasPrice,
			totalTravelActivitiesPrice,
		]
	}, [transports, transportExtras, travelActivities])

	const hideCabTypesFromEachService = Boolean(
		usedCabTypes?.length && cabsPriceCalculations?.length
	)

	const hasMultipleComponentPrices =
		[
			totalTransportsPrice,
			totalTransportExtrasPrice,
			totalTravelActivitiesPrice,
		].filter((t) => t.length).length > 1

	return daywiseServices.length ||
		extraServiceWithoutDates.length ||
		travelActivities?.length ? (
		<Box as="section">
			<Stack gap="4">
				<Inline gap="2" alignItems="center">
					<Box>
						<SectionIcon>
							<Icons.Bus size="6" />
						</SectionIcon>
					</Box>
					<Heading as="h5" fontSize="md">
						Transportation and Activities
					</Heading>
				</Inline>
				<Stack gap="6" flex="1" minWidth="0" width="full">
					{hideCabTypesFromEachService ? (
						<Inline
							padding="4"
							gap="4"
							rounded="md"
							bgColor="accent"
							borderColor="accent"
							borderWidth="1"
						>
							<Text fontWeight="semibold">Transportation/Type:</Text>
							<Text>
								{usedCabTypes
									?.map(
										(t) =>
											`${t.no_of_cabs}-${t.cab_type.name}${
												t.cab_type.deleted_at ? ` [Disabled]` : ``
											}`
									)
									.join(" + ")}
							</Text>
						</Inline>
					) : null}
					{daywiseServices.map(
						({ date, date_local, transportsAndActivities, extras }) => {
							return (
								<Inline
									position="relative"
									gap="4"
									collapseBelow="sm"
									key={date}
								>
									<Box position="sticky" top="0" zIndex="10">
										<Box position="sticky" top="0" zIndex="10">
											<Box
												bgColor="default"
												textAlign="center"
												paddingX="4"
												paddingY="3"
												rounded="md"
												borderColor="white"
												style={{ minWidth: "110px" }}
											>
												<Text fontWeight="semibold" fontSize="md">
													{withOrdinalSuffix(
														getDiff(
															utcTimestampToLocalDate(date),
															utcTimestampToLocalDate(startDateUTC),
															"day"
														) + 1
													)}{" "}
													Day
												</Text>
												<Time
													timestamp={date}
													localTimestamp={date_local}
													format="ddd, D MMM"
													whiteSpace="preserve"
													fontSize="sm"
												/>
											</Box>
										</Box>
									</Box>
									<Box flex="1">
										<Stack gap="6">
											<Stack bgColor="default" rounded="md" borderWidth="1">
												{transportsAndActivities.map(
													({ type, transport, activity }, i) => {
														if (type === "transport") {
															const {
																transport_service,
																fetching_prices,
																cabs,
															} = transport

															const currency = cabs[0].currency
															const totalGivenPrice = cabs.reduce<number>(
																(total, { given_price }) =>
																	total + Number(given_price || 0),
																0
															)
															const totalCalculatedPrice = cabs.reduce<
																number | undefined
															>(
																(total, { calculated_price }) =>
																	calculated_price === undefined ||
																	calculated_price === null
																		? undefined
																		: Number(total || 0) +
																			Number(calculated_price || 0),
																undefined
															)
															const isUsingSystemRates =
																totalCalculatedPrice &&
																Number(totalCalculatedPrice) ===
																	Number(totalGivenPrice)
															return (
																<Inline
																	key={`${transport_service.id}-${i}`}
																	gap="6"
																	justifyContent="between"
																	padding="4"
																	borderTopWidth={i > 0 ? "1" : undefined}
																>
																	<Grid gap="4">
																		<Col
																			xs={12}
																			sm={hideCabTypesFromEachService ? 12 : 6}
																		>
																			<Stack gap="px">
																				<Text fontWeight="semibold">
																					{transport_service.from_to}
																				</Text>
																				<Text fontSize="sm">
																					{transport_service.service}
																				</Text>
																				{transport_service.start_time_formatted ||
																				transport_service.duration_string_short ||
																				transport_service.end_time_formatted ? (
																					<Text fontSize="sm" color="muted">
																						{joinAttributes(
																							transport_service.start_time_formatted
																								? `Starts: ${transport_service.start_time_formatted}`
																								: null,
																							transport_service.duration_string_short
																								? `Duration: ${transport_service.duration_string_short}${
																										transport_service.end_time_formatted
																											? ` (Ends: ${transport_service.end_time_formatted})`
																											: ""
																									}`
																								: null
																						)}
																					</Text>
																				) : null}
																				{transport_service.deleted_at ? (
																					<Box>
																						<Badge warning>
																							<Icons.Ban /> Disabled
																						</Badge>
																					</Box>
																				) : null}
																			</Stack>
																		</Col>
																		{hideCabTypesFromEachService ? null : (
																			<Col>
																				<Stack gap="1">
																					<Inline gap="4" flexWrap="wrap">
																						{cabs.map(
																							(
																								{
																									cab_type,
																									cab_locality,
																									no_of_cabs,
																									calculated_price,
																									given_price,
																									currency,
																								},
																								index
																							) => (
																								<Stack
																									key={`${transport_service.id}-${index}`}
																								>
																									<Stack>
																										<Text fontWeight="semibold">
																											{no_of_cabs}-
																											{cab_type.name}{" "}
																											{cab_type.deleted_at ? (
																												<Badge warning>
																													<Icons.Ban /> Disabled
																												</Badge>
																											) : null}
																										</Text>
																										{cab_locality ? (
																											<Text fontSize="sm">
																												{cab_locality.name}
																											</Text>
																										) : null}
																									</Stack>
																									{hidePrice ||
																									cabsPriceCalculations?.length ? null : (
																										<Box fontSize="sm">
																											{given_price ? (
																												<Stack>
																													<Money
																														currency={currency}
																														color={
																															calculated_price &&
																															given_price ===
																																calculated_price
																																? "success"
																																: "muted"
																														}
																														amount={
																															given_price || 0
																														}
																													/>
																												</Stack>
																											) : (
																												<Text color="warning">
																													N/A
																												</Text>
																											)}
																										</Box>
																									)}
																								</Stack>
																							)
																						)}
																					</Inline>
																					{cabs?.[0]?.comments?.length ? (
																						<Text
																							fontSize="sm"
																							color="muted"
																							maxWidth="lg"
																						>
																							<Icons.Annotation />{" "}
																							{collect(
																								cabs
																									.map((c) => c.comments)
																									.filter((c): c is string =>
																										Boolean(c?.length)
																									)
																							)
																								.unique()
																								.toArray()
																								.join(". ")}
																						</Text>
																					) : null}
																				</Stack>
																			</Col>
																		)}
																	</Grid>
																	{!hidePrice &&
																	!cabsPriceCalculations?.length ? (
																		<Box
																			style={{ width: "60px" }}
																			textAlign="right"
																		>
																			<Stack gap="1" alignItems="end">
																				<Inline
																					gap="1"
																					alignItems="center"
																					fontWeight="semibold"
																				>
																					{isUsingSystemRates ? (
																						<Icons.Ok
																							color="success"
																							title="Using System Rate"
																						/>
																					) : null}
																					<Money
																						amount={totalGivenPrice || 0}
																						currency={currency}
																						showCurrency
																					/>
																				</Inline>
																				{isUsingSystemRates ? null : (
																					<Text
																						color="muted"
																						fontSize="xs"
																						title="System Rate"
																					>
																						/{" "}
																						{totalCalculatedPrice !==
																						undefined ? (
																							<Money
																								amount={totalCalculatedPrice}
																								currency={currency}
																							/>
																						) : (
																							"N/A"
																						)}
																					</Text>
																				)}
																				{fetching_prices ? (
																					<Text>
																						<Badge warning>
																							<Icons.Refresh spin size="3" />{" "}
																							Rates
																						</Badge>
																					</Text>
																				) : null}
																			</Stack>
																		</Box>
																	) : null}
																</Inline>
															)
														} else if (type === "activity") {
															return (
																<Box
																	padding="4"
																	key={`${activity.id}`}
																	borderTopWidth={i > 0 ? "1" : undefined}
																>
																	<QuoteTravelActivityListItem
																		activity={activity}
																		hidePrice={hidePrice}
																	/>
																</Box>
															)
														}
														return null
													}
												)}
											</Stack>
											{extras.length ? (
												<Stack gap="1">
													<Box paddingLeft="4">
														<Heading color="primary" fontSize="base">
															Other Inclusions
														</Heading>
													</Box>
													<Stack bgColor="default" rounded="md" borderWidth="1">
														{extras.map(
															({ service, given_price, currency }, i) => {
																return (
																	<Inline
																		gap="6"
																		justifyContent="between"
																		padding="4"
																		borderTopWidth={i > 0 ? "1" : undefined}
																		key={`${service.id}-${i}`}
																	>
																		<Box key={service.id}>
																			<Stack gap="px">
																				<Text fontWeight="semibold">
																					{service.name}
																				</Text>
																			</Stack>
																		</Box>
																		{!hidePrice ? (
																			<Box
																				style={{ width: "60px" }}
																				textAlign="right"
																			>
																				{given_price ? (
																					<Money
																						currency={currency}
																						amount={given_price}
																						fontWeight="semibold"
																						showCurrency
																					/>
																				) : null}
																			</Box>
																		) : null}
																	</Inline>
																)
															}
														)}
													</Stack>
												</Stack>
											) : null}
										</Stack>
									</Box>
								</Inline>
							)
						}
					)}
					{cabsPriceCalculations?.length && !hidePrice ? (
						<Inline
							padding="4"
							gap="4"
							rounded="md"
							bgColor="accent"
							borderColor="accent"
							borderWidth="1"
						>
							<Text fontWeight="semibold">Prices using Calculator:</Text>
							<Box as="ul">
								{cabsPriceCalculations?.map(
									({
										id,
										cab_type,
										no_of_cabs,
										currency,
										per_quantity_excluding_tax_given_price,
										given_price,
										metric_wise_prices,
										fetching_prices,
									}) => {
										return (
											<Box as="li" key={id}>
												<Text as="span" fontWeight="semibold">
													{no_of_cabs}-{cab_type.name}
												</Text>
												={" "}
												<Money
													amount={given_price || 0}
													currency={currency}
													showCurrency
													fontWeight="semibold"
												/>{" "}
												{!given_price ? null : (
													<Text as="span" color="muted">
														(
														{metric_wise_prices.map(
															(
																{
																	id,
																	metric,
																	given_quantity,
																	per_quantity_price,
																	per_quantity_given_price,
																	currency,
																},
																index
															) =>
																metric &&
																given_quantity &&
																per_quantity_given_price ? (
																	<Text as="span" key={id}>
																		{index > 0 ? " + " : null}
																		{numberToLocalString(given_quantity)} x{" "}
																		<Money
																			amount={per_quantity_given_price}
																			currency={currency}
																		/>{" "}
																		{Number(per_quantity_given_price) &&
																		Number(per_quantity_given_price) ===
																			Number(per_quantity_price) ? (
																			<>
																				<Icons.Ok
																					color="success"
																					title="Using System Rates"
																				/>{" "}
																			</>
																		) : null}
																		/ {metric.name}
																	</Text>
																) : null
														)}{" "}
														={" "}
														<Text as="span">
															<Money
																amount={per_quantity_excluding_tax_given_price}
																currency={currency}
															/>
															{taxPercentage
																? ` + ${numberToLocalString(taxPercentage)}%`
																: null}
														</Text>
														)
														{no_of_cabs > 1 ? (
															<Text as="span"> x {no_of_cabs}</Text>
														) : null}
													</Text>
												)}
												{fetching_prices ? (
													<Badge warning>
														<Icons.Refresh spin size="3" /> Rates
													</Badge>
												) : null}
											</Box>
										)
									}
								)}
							</Box>
						</Inline>
					) : null}
					{extraServiceWithoutDates.length ? (
						<Box>
							<Heading fontSize="md" as="h6">
								Other Transportation Inclusions
							</Heading>
							<Box>
								<Stack bgColor="default" rounded="md" borderWidth="1">
									{extraServiceWithoutDates.map(
										({ id, service, currency, given_price, comments }, i) => (
											<Inline
												key={id}
												gap="6"
												justifyContent="between"
												padding="4"
												borderTopWidth={i > 0 ? "1" : undefined}
											>
												<Stack>
													<Box>
														<Text fontWeight="semibold">{service.name}</Text>
													</Box>
													{comments ? (
														<Text maxWidth="lg" fontSize="sm" color="muted">
															<Icons.Annotation /> {comments}
														</Text>
													) : null}
												</Stack>
												{!hidePrice ? (
													<Box style={{ width: "60px" }} textAlign="right">
														<Money
															amount={given_price || 0}
															currency={currency}
															fontWeight="semibold"
															showCurrency
														/>
													</Box>
												) : null}
											</Inline>
										)
									)}
								</Stack>
							</Box>
						</Box>
					) : null}
				</Stack>
			</Stack>
			{!hidePrice ? (
				<Inline justifyContent="end" textAlign="right" marginTop="4">
					<Stack
						paddingX="4"
						paddingY="1"
						bgColor="subtle"
						borderWidth="1"
						rounded="md"
						fontWeight="semibold"
						gap="1"
					>
						{hasMultipleComponentPrices ? (
							<Box>
								Total: <MoneySum money={totalGivenPrice} />
							</Box>
						) : null}
						<Text fontSize={hasMultipleComponentPrices ? "sm" : undefined}>
							{joinAttributes(
								totalTransportsPrice?.length ? (
									<>
										{" "}
										Cab's: <MoneySum money={totalTransportsPrice} />
									</>
								) : null,
								totalTravelActivitiesPrice?.length ? (
									<>
										Activity/Ticket's:{" "}
										<MoneySum money={totalTravelActivitiesPrice} />
									</>
								) : null,
								totalTransportExtrasPrice?.length ? (
									<>
										Other's: <MoneySum money={totalTransportExtrasPrice} />
									</>
								) : null
							)}
						</Text>
					</Stack>
				</Inline>
			) : null}
		</Box>
	) : null
}

function QuoteTravelActivityListItem({
	activity: quoteTravelActivity,
	hidePrice,
}: {
	activity: Omit<
		IQuote["travel_activities"][number],
		"ticket_tourist_configurations"
	> & {
		ticket_tourist_configurations: Array<
			IQuote["travel_activities"][number]["ticket_tourist_configurations"][number] & {
				fetching_prices?: boolean
			}
		>
	}
	hidePrice?: boolean
}) {
	const {
		activity,
		ticket_type,
		duration,
		slot,
		ticket_tourist_configurations,
		comments,
	} = quoteTravelActivity
	const totalGivenPrice = ticket_tourist_configurations.reduce<number>(
		(total, { given_price }) => total + Number(given_price || 0),
		0
	)
	const totalCalculatedPrice = ticket_tourist_configurations.reduce<
		number | undefined
	>(
		(total, { calculated_price }) =>
			calculated_price === undefined || calculated_price === null
				? undefined
				: Number(total || 0) + Number(calculated_price || 0),
		0
	)

	const isUsingSystemRates =
		Number(totalCalculatedPrice) &&
		Number(totalGivenPrice) === Number(totalCalculatedPrice)

	const currency = ticket_tourist_configurations[0].currency
	const fetching_prices = ticket_tourist_configurations.some((s) =>
		Boolean(s.fetching_prices)
	)
	return (
		<Inline gap="6" justifyContent="between">
			<Grid gap="4">
				<Col xs={12} sm={6}>
					<Stack gap="px">
						<Text fontWeight="semibold">
							{activity.name}{" "}
							{activity.deleted_at ? (
								<Badge warning>
									<Icons.Ban /> Disabled
								</Badge>
							) : null}
						</Text>
						{ticket_type ? (
							<Text fontSize="sm">
								{ticket_type.name}
								{duration
									? ` ${slot ? `- ${slot} hrs ` : ``}(${createDuration(
											duration
										).asMinutes()} mins)`
									: null}{" "}
								{ticket_type.deleted_at ? (
									<Badge warning>
										<Icons.Ban /> Disabled
									</Badge>
								) : null}
							</Text>
						) : null}
					</Stack>
				</Col>
				<Col>
					<Inline gap="4" flexWrap="wrap">
						{ticket_tourist_configurations.map(
							(
								{
									configuration,
									quantity,
									calculated_price,
									per_quantity_given_price,
									given_price,
									currency,
								},
								index
							) => (
								<Stack key={`${activity.id}-${index}`}>
									<Text fontWeight="semibold">
										{quantity} {configuration.name}
									</Text>
									{hidePrice ? null : (
										<Box fontSize="sm">
											{per_quantity_given_price ? (
												<Text>
													<Money
														color={
															calculated_price &&
															Number(calculated_price) === Number(given_price)
																? "success"
																: "muted"
														}
														amount={per_quantity_given_price}
														currency={currency}
													/>{" "}
													* {quantity}
												</Text>
											) : (
												<Text color="warning">N/A</Text>
											)}
										</Box>
									)}
								</Stack>
							)
						)}
					</Inline>
					{comments ? (
						<Box paddingTop="1">
							<Text maxWidth="lg" color="muted" fontSize="sm">
								<Icons.Annotation /> {comments}
							</Text>
						</Box>
					) : null}
				</Col>
			</Grid>
			{!hidePrice ? (
				<Box style={{ width: "60px" }} textAlign="right">
					<Stack gap="1" alignItems="end">
						<Inline gap="1" alignItems="center" fontWeight="semibold">
							{isUsingSystemRates ? (
								<Icons.Ok color="success" title="Using System Rate" />
							) : null}
							<Money
								amount={totalGivenPrice}
								currency={currency}
								showCurrency
							/>
						</Inline>
						{isUsingSystemRates ? null : (
							<Text color="muted" fontSize="xs" title="System Rate">
								/{" "}
								{totalCalculatedPrice ? (
									<Money amount={totalCalculatedPrice} currency={currency} />
								) : (
									"N/A"
								)}
							</Text>
						)}
						{fetching_prices ? (
							<Text>
								<Badge warning>
									<Icons.Refresh spin size="3" /> Rates
								</Badge>
							</Text>
						) : null}
					</Stack>
				</Box>
			) : null}
		</Inline>
	)
}

export function QuoteExtras({
	extras,
	hidePrice,
	startDateUTC,
}: {
	extras: $PropertyType<IQuote, "other_extras">
	hidePrice?: boolean
	startDateUTC: string
}) {
	return extras.length ? (
		<Box as="section">
			<Box display="flex" overflow="auto">
				<Box display={{ xs: "none", sm: "block" }}>
					<SectionIcon>
						<Icons.StarEmpty size="6" />
					</SectionIcon>
				</Box>
				<Box width="full" flex="1" minWidth="0">
					<Box display="flex" marginBottom="2" alignItems="center">
						<Box display={{ xs: "block", sm: "none" }}>
							<SectionIcon>
								<Icons.StarEmpty size="6" />
							</SectionIcon>
						</Box>
						<Heading as="h5" fontSize="md">
							Other Special Inclusions
						</Heading>
					</Box>
					<Box display={{ xs: "block", sm: "none" }}>
						<Box as="ol" marginBottom="8">
							{extras.map(
								({ id, service, date, currency, given_price, comments }) => (
									<Box
										as="li"
										key={id}
										borderWidth="1"
										rounded="md"
										marginBottom="4"
										overflow="hidden"
										bgColor="default"
									>
										<Inline padding="4" gap="4">
											{date ? (
												<Time timestamp={date}>
													<Stack style={{ width: "36px" }}>
														<Text fontWeight="semibold">
															{withOrdinalSuffix(
																getDiff(
																	utcTimestampToLocalDate(date),
																	utcTimestampToLocalDate(startDateUTC),
																	"day"
																) + 1
															)}
														</Text>
														<Text color="muted" fontSize="sm">
															Day
														</Text>
													</Stack>
												</Time>
											) : null}
											<Stack gap="2">
												<Inline justifyContent="between" gap="4">
													<Text fontWeight="semibold">{service.name}</Text>
													{!hidePrice ? (
														<Box>
															<Money
																currency={currency}
																amount={given_price || 0}
																fontWeight="semibold"
																showCurrency
															/>
														</Box>
													) : null}
												</Inline>
												{comments ? (
													<Text maxWidth="lg" color="muted" fontSize="sm">
														<Icons.Annotation /> {comments}
													</Text>
												) : null}
											</Stack>
										</Inline>
									</Box>
								)
							)}
						</Box>
					</Box>
					<Box display={{ xs: "none", sm: "block" }}>
						<Table
							bordered
							responsive
							headers={["Day", "Service"].concat(hidePrice ? [] : ["Price"])}
							alignCols={{ 2: "right" }}
							rows={extras.map(
								({
									service,
									date,
									date_local,
									currency,
									given_price,
									comments,
								}) =>
									[
										date ? (
											<Stack>
												<Text fontWeight="semibold">
													{withOrdinalSuffix(
														getDiff(
															utcTimestampToLocalDate(date),
															utcTimestampToLocalDate(startDateUTC),
															"day"
														) + 1
													)}
												</Text>
												<Box>
													<Time
														timestamp={date}
														localTimestamp={date_local}
														format="ddd, D MMM"
														whiteSpace="preserve"
														fontSize="sm"
													/>
												</Box>
											</Stack>
										) : null,
										<Stack>
											<Box fontWeight="semibold">{service.name}</Box>
											{comments ? (
												<Text maxWidth="lg" color="muted" fontSize="sm">
													<Icons.Annotation /> {comments}
												</Text>
											) : null}
										</Stack>,
									].concat(
										hidePrice
											? []
											: [
													<Money
														amount={given_price || 0}
														currency={currency}
														fontWeight="semibold"
														showCurrency
													/>,
												]
									)
							)}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	) : null
}

function SectionIcon(props: React.ComponentProps<typeof Box>) {
	return (
		<Box
			display="flex"
			width="12"
			height="12"
			alignItems="center"
			justifyContent="center"
			bgColor="primary"
			color="primary"
			borderWidth="1"
			borderColor="primary"
			fontSize="xl"
			rounded="full"
			marginRight="2"
			{...props}
		/>
	)
}

export function LatestQuoteDiffFromBookingsForTrip({
	tripId,
	containerPadding,
}: {
	tripId: number | string
	containerPadding?: BoxOwnProps["padding"]
}) {
	const { diff } = useDiffBetweenLatestQuoteAndBookings(tripId)
	return diff && diff.changed ? (
		<Box padding={containerPadding}>
			<Alert
				status="warning"
				title="Services details (Hotels/Transports) from Latest Quote and Latest Bookings are not up-to-date with each other."
			>
				<Stack gap="2">
					<Text>
						Some services provided in the latest quote have been updated in the
						booking process or vice-versa. Please check the services in quote
						and bookings, and update the details as per the requirements.
					</Text>
					<Component initialState={false}>
						{({ state: open, setState }) => (
							<Box>
								<Button onClick={() => setState(true)} status="warning">
									View Changes
								</Button>
								<Dialog open={open} onClose={() => setState(false)} xl>
									<Dialog.Header closeButton>
										<Dialog.Title>
											Changes in Services during booking
										</Dialog.Title>
									</Dialog.Header>
									<Dialog.Body>
										<Stack gap="4">
											<Stack
												gap="1"
												paddingX="4"
												paddingY="2"
												bgColor="subtle"
												rounded="md"
												borderWidth="1"
											>
												<Text color="danger">
													<Icons.Cancel />: exists in quotation but removed
													during bookings.
												</Text>
												<Text color="success">
													<Icons.Plus />: changed/added during bookings.
												</Text>
											</Stack>
											{diff.hotels_changed || diff.hotel_extras_changed ? (
												<DiffInQuoteHotels
													hotels={diff.hotels}
													hotelExtras={diff.hotel_extras}
												/>
											) : null}
											{diff.cabs_changed ? (
												<DiffInQuoteCabs cabs={diff.cabs} />
											) : null}
											{diff.travel_activities_changed ? (
												<DiffInQuoteTravelActivities
													travelActivities={diff.travel_activities}
												/>
											) : null}
										</Stack>
									</Dialog.Body>
									<Dialog.Footer>
										<Stack gap="2">
											<Text>
												<b>Next:</b> Please update the bookings/quotation and
												match the details.
											</Text>
											<Box>
												<Button level="primary" onClick={() => setState(false)}>
													Ok, Got it.
												</Button>
											</Box>
										</Stack>
									</Dialog.Footer>
								</Dialog>
							</Box>
						)}
					</Component>
				</Stack>
			</Alert>
		</Box>
	) : null
}

function DiffInQuoteHotels({
	hotels,
	hotelExtras,
}: {
	hotels: $PropertyType<IQuoteDiff, "hotels">
	hotelExtras: $PropertyType<IQuoteDiff, "hotel_extras">
}) {
	return hotels.length || hotelExtras.length ? (
		<Box as="section">
			<Inline gap="4">
				<Box>
					<SectionIcon>
						<Icons.Bed size="6" />
					</SectionIcon>
				</Box>
				<Stack flex="1" minWidth="0" width="full" gap="4">
					{hotels.length ? (
						<Stack gap="2">
							<Heading as="h6" fontSize="md">
								Accommodation
							</Heading>
							<Table
								bordered
								responsive
								headers={["", "Date", "Hotel", "Meal Plan", "Rooms"]}
							>
								<tbody>
									{hotels.map((quoteHotel, i) => {
										const {
											added,
											removed,
											hotel,
											date,
											date_local,
											meal_plan,
											room_type,
											no_of_rooms,
											persons_per_room,
											comments,
											adults_with_extra_bed,
											children_with_extra_bed,
											children_without_extra_bed,
										} = quoteHotel
										return (
											<Box
												as="tr"
												key={i}
												title={
													added ? "Added" : removed ? "Changed" : "Unchanged"
												}
												bgColor={
													added ? "success" : removed ? "danger" : undefined
												}
											>
												<TableDataCell
													textAlign="center"
													verticalAlign="middle"
													style={{
														width: "50px",
													}}
													bgColor={
														added
															? "success_emphasis"
															: removed
																? "danger_emphasis"
																: undefined
													}
													color={
														added
															? "on_emphasis"
															: removed
																? "on_emphasis"
																: undefined
													}
												>
													{added ? (
														<Icons.Plus title="Added" />
													) : removed ? (
														<Icons.Cancel title="Changed" />
													) : null}
												</TableDataCell>
												<TableDataCell>
													<Time
														whiteSpace="preserve"
														timestamp={date}
														localTimestamp={date_local}
													/>
												</TableDataCell>
												<TableDataCell>
													<Box>
														<Box display="inline" fontWeight="semibold">
															{hotel.name}
														</Box>{" "}
														<Box fontSize="sm" display="inlineBlock">
															{hotel.location.short_name}
															{hotel.stars_string
																? `, ${hotel.stars_string}`
																: null}
														</Box>
														{hotel.deleted_at ? (
															<Badge warning>Disabled</Badge>
														) : null}
														{comments ? (
															<blockquote>{comments}</blockquote>
														) : null}
													</Box>
												</TableDataCell>
												<TableDataCell>{meal_plan.name}</TableDataCell>
												<TableDataCell>
													<Box>
														<Box display="inline" fontWeight="semibold">
															{no_of_rooms} {room_type.name}
														</Box>{" "}
														<Box display="inlineBlock" fontSize="sm">
															(
															{joinAttributes(
																`${persons_per_room}P`,
																[
																	adults_with_extra_bed,
																	`${adults_with_extra_bed} AwEb`,
																],
																[
																	children_with_extra_bed,
																	`${children_with_extra_bed} CwEb`,
																],
																[
																	children_without_extra_bed,
																	`${children_without_extra_bed} CNB`,
																]
															)}
															)
														</Box>
													</Box>
												</TableDataCell>
											</Box>
										)
									})}
								</tbody>
							</Table>
						</Stack>
					) : null}
					{hotelExtras.length ? (
						<Stack gap="2">
							<Heading as="h6" fontSize="md">
								Hotel Special Inclusions
							</Heading>
							<Table
								bordered
								responsive
								headers={["", "Service", "Date", "Hotel"]}
							>
								<tbody>
									{hotelExtras.map(
										(
											{
												service,
												date,
												date_local,
												hotel,
												comments,
												added,
												removed,
											},
											i
										) => (
											<Box
												as="tr"
												key={i}
												title={
													added ? "Added" : removed ? "Changed" : "Unchanged"
												}
												backgroundColor={
													added ? "success" : removed ? "danger" : undefined
												}
											>
												<TableDataCell
													textAlign="center"
													verticalAlign="middle"
													style={{
														width: "50px",
													}}
													bgColor={
														added ? "success" : removed ? "danger" : undefined
													}
													color={
														added ? "success" : removed ? "danger" : undefined
													}
												>
													{added ? (
														<Icons.Plus title="Added" />
													) : removed ? (
														<Icons.Cancel title="Changed" />
													) : null}
												</TableDataCell>
												<TableDataCell>
													<Box>
														<Box fontWeight="semibold">{service.name}</Box>
														{comments ? (
															<blockquote>{comments}</blockquote>
														) : null}
													</Box>
												</TableDataCell>
												<TableDataCell>
													{date ? (
														<Time
															whiteSpace="preserve"
															timestamp={date}
															localTimestamp={date_local}
														/>
													) : null}
												</TableDataCell>
												<TableDataCell>
													{hotel && (
														<Box>
															<Box fontWeight="semibold" display="inline">
																{hotel.name}
															</Box>{" "}
															<Box fontSize="sm" display="inline">
																{hotel.location.short_name}
																{hotel.stars_string
																	? `, ${hotel.stars_string}`
																	: null}
															</Box>
														</Box>
													)}
												</TableDataCell>
											</Box>
										)
									)}
								</tbody>
							</Table>
						</Stack>
					) : null}
				</Stack>
			</Inline>
		</Box>
	) : null
}

function DiffInQuoteCabs({
	cabs,
}: {
	cabs: $PropertyType<IQuoteDiff, "cabs">
}) {
	return cabs.length ? (
		<Box as="section">
			<Inline gap="4">
				<Box>
					<SectionIcon>
						<Icons.Taxi size="6" />
					</SectionIcon>
				</Box>
				<Stack flex="1" minWidth="0" width="full" gap="4">
					{cabs.length ? (
						<Stack gap="2">
							<Heading as="h6" fontSize="md">
								Transportation
							</Heading>
							<Table
								bordered
								responsive
								headers={["", "Date", "Transport Service", "Cabs"]}
							>
								<tbody>
									{cabs.map((quoteCab, i) => {
										const {
											added,
											removed,
											transport_service,
											date,
											date_local,
											cab_type,
											no_of_cabs,
										} = quoteCab
										return (
											<Box
												as="tr"
												key={i}
												title={
													added ? "Added" : removed ? "Changed" : "Unchanged"
												}
												bgColor={
													added ? "success" : removed ? "danger" : undefined
												}
											>
												<TableDataCell
													textAlign="center"
													verticalAlign="middle"
													style={{
														width: "50px",
													}}
													bgColor={
														added
															? "success_emphasis"
															: removed
																? "danger_emphasis"
																: undefined
													}
													color={
														added
															? "on_emphasis"
															: removed
																? "on_emphasis"
																: undefined
													}
												>
													{added ? (
														<Icons.Plus title="Added" />
													) : removed ? (
														<Icons.Cancel title="Changed" />
													) : null}
												</TableDataCell>
												<TableDataCell>
													<Time
														whiteSpace="preserve"
														timestamp={date}
														localTimestamp={date_local}
													/>
												</TableDataCell>
												<TableDataCell>
													<Box fontWeight="semibold">
														{transport_service.name}
													</Box>
												</TableDataCell>
												<TableDataCell>
													{no_of_cabs}-{cab_type.name}
												</TableDataCell>
											</Box>
										)
									})}
								</tbody>
							</Table>
						</Stack>
					) : null}
				</Stack>
			</Inline>
		</Box>
	) : null
}

function DiffInQuoteTravelActivities({
	travelActivities,
}: {
	travelActivities: $PropertyType<IQuoteDiff, "travel_activities">
}) {
	return travelActivities.length ? (
		<Box as="section">
			<Inline gap="4">
				<Box>
					<SectionIcon>
						<Icons.Ticket size="6" />
					</SectionIcon>
				</Box>
				<Stack flex="1" minWidth="0" width="full" gap="4">
					{travelActivities.length ? (
						<Stack gap="2">
							<Heading as="h6" fontSize="md">
								Travel Activities / Tickets
							</Heading>
							<Table
								bordered
								responsive
								headers={["", "Date", "Service", "Configruation"]}
							>
								<tbody>
									{travelActivities.map((quoteCab, i) => {
										const {
											added,
											removed,
											activity,
											date,
											date_local,
											ticket_type,
											ticket_tourist_configurations,
										} = quoteCab
										return (
											<Box
												as="tr"
												key={i}
												title={
													added ? "Added" : removed ? "Changed" : "Unchanged"
												}
												bgColor={
													added ? "success" : removed ? "danger" : undefined
												}
											>
												<TableDataCell
													textAlign="center"
													verticalAlign="middle"
													style={{
														width: "50px",
													}}
													bgColor={
														added
															? "success_emphasis"
															: removed
																? "danger_emphasis"
																: undefined
													}
													color={
														added
															? "on_emphasis"
															: removed
																? "on_emphasis"
																: undefined
													}
												>
													{added ? (
														<Icons.Plus title="Added" />
													) : removed ? (
														<Icons.Cancel title="Changed" />
													) : null}
												</TableDataCell>
												<TableDataCell>
													<Time
														whiteSpace="preserve"
														timestamp={date}
														localTimestamp={date_local}
													/>
												</TableDataCell>
												<TableDataCell>
													<Box fontWeight="semibold">
														{activity.name}
														{ticket_type ? `-${ticket_type.name}` : ``}
													</Box>
												</TableDataCell>
												<TableDataCell>
													{ticket_tourist_configurations
														? joinAttributes(
																...ticket_tourist_configurations.map(
																	(config) =>
																		`${config.quantity} ${config.configuration.name}`
																)
															)
														: null}
												</TableDataCell>
											</Box>
										)
									})}
								</tbody>
							</Table>
						</Stack>
					) : null}
				</Stack>
			</Inline>
		</Box>
	) : null
}

export function QuoteFlights({
	flights,
	hidePrice,
}: {
	flights: $PropertyType<IQuote, "flights">
	hidePrice?: boolean
	startDateUTC?: string
}) {
	return flights.length ? (
		<Box as="section">
			<Box display="flex" overflow="auto">
				<Box display={{ xs: "none", sm: "block" }}>
					<SectionIcon>
						<Icons.Airplane size="6" />
					</SectionIcon>
				</Box>
				<Box flex="1" width="full" minWidth="0">
					<Box>
						<Box display="flex" marginBottom="2" alignItems="center">
							<Box display={{ xs: "block", sm: "none" }} marginRight="2">
								<SectionIcon>
									<Icons.Airplane size="6" />
								</SectionIcon>
							</Box>
							<Heading as="h5" fontSize="md">
								Flights
							</Heading>
						</Box>
						<Box display={{ xs: "block", sm: "none" }}>
							<Box as="ol" marginBottom="8">
								{flights.map(
									({
										id,
										source,
										destination,
										departs_at,
										departs_at_local,
										arrives_at,
										arrives_at_local,
										airline,
										category_class,
										number_plate,
										no_of_adults,
										children,
										infants,
										currency,
										calculated_price,
										given_price,
										comments,
									}) => (
										<Box
											as="li"
											key={id}
											marginBottom="4"
											borderWidth="1"
											rounded="md"
											overflow="hidden"
											bgColor="default"
										>
											<Box
												display="flex"
												padding="4"
												bgColor="subtle"
												alignItems="center"
											>
												<Box>
													<Box fontWeight="semibold">{source.name}</Box>
													<Box whiteSpace="preserve" fontWeight="semibold">
														<Time
															timestamp={departs_at}
															localTimestamp={departs_at_local}
															format="D MMM [at] HH:mm [hrs]"
														/>
													</Box>
													<Box fontSize="sm" color="muted">
														{formatDate(
															localOrUtcTimestampToLocalDate(
																departs_at_local,
																departs_at
															),
															"ddd YYYY"
														)}
													</Box>
												</Box>
												<Box fontFamily="mono" marginX="4">
													&#8594;
												</Box>
												<Box>
													<Box>
														<Box fontWeight="semibold">{destination.name}</Box>
														<Box whiteSpace="preserve" fontWeight="semibold">
															<Time
																timestamp={arrives_at}
																localTimestamp={arrives_at_local}
																format="D MMM [at] HH:mm [hrs]"
															/>
														</Box>
														<Box fontSize="sm" color="muted">
															{formatDate(
																localOrUtcTimestampToLocalDate(
																	arrives_at_local,
																	arrives_at
																),
																"ddd YYYY"
															)}
														</Box>
													</Box>
												</Box>
											</Box>
											<Box paddingX="4" paddingY="2">
												<Box
													display="flex"
													justifyContent="between"
													marginBottom="2"
												>
													<Box display="flex" alignItems="center">
														<Box marginRight="2">
															<Icons.Airplane rotate="90" color="muted" />
														</Box>
														<Box fontWeight="semibold">{airline.name}</Box>
														<Box marginLeft="2">{number_plate}</Box>
													</Box>
													<Box>{category_class}</Box>
												</Box>
												<Box display="flex" justifyContent="between">
													<Box display="flex" alignItems="center">
														<Box marginRight="2">
															<Icons.UserGroup color="muted" />
														</Box>
														{joinAttributes(
															pluralize("Adult", no_of_adults, true),
															[infants, `${infants}Inf`],
															[children, `${children}C`]
														)}
													</Box>
													{!hidePrice ? (
														<Box>
															<Box fontWeight="semibold" display="inlineBlock">
																<Money
																	amount={given_price}
																	currency={currency}
																/>
																{calculated_price ? (
																	<>
																		<Box
																			title="Calculate Price"
																			as="span"
																			fontSize="xs"
																			color="muted"
																		>
																			{" "}
																			/{" "}
																			<Money
																				amount={calculated_price}
																				currency={currency}
																				showCurrency
																			/>
																		</Box>
																	</>
																) : null}
															</Box>
														</Box>
													) : null}
												</Box>
												{comments ? (
													<Box
														as="blockquote"
														maxWidth="sm"
														marginTop="2"
														marginBottom="0"
													>
														{comments}
													</Box>
												) : null}
											</Box>
										</Box>
									)
								)}
							</Box>
						</Box>
						<Box display={{ xs: "none", sm: "block" }}>
							<Table
								bordered
								responsive
								headers={["From", "Destination", "Flight"].concat(
									hidePrice ? [] : ["Price"]
								)}
								alignCols={{ 3: "right" }}
								rows={flights.map(
									({
										source,
										destination,
										departs_at,
										departs_at_local,
										arrives_at,
										arrives_at_local,
										airline,
										category_class,
										number_plate,
										no_of_adults,
										children,
										infants,
										currency,
										calculated_price,
										given_price,
										comments,
									}) =>
										[
											<Box whiteSpace="preserve">
												<Box fontWeight="semibold">{source.name}</Box>
												<Time
													timestamp={departs_at}
													localTimestamp={departs_at_local}
													format="DD MMM, YYYY [at] HH:mm [hrs]"
												/>
											</Box>,
											<Box whiteSpace="preserve">
												<Box fontWeight="semibold">{destination.name}</Box>
												<Time
													timestamp={arrives_at}
													localTimestamp={arrives_at_local}
													format="DD MMM, YYYY [at] HH:mm [hrs]"
												/>
											</Box>,
											<Box>
												<Box fontWeight="semibold">
													{airline.name}-{category_class}-{number_plate}
												</Box>
												{joinAttributes(
													`${no_of_adults}A`,
													[infants, `${infants}Inf`],
													[children, `${children}C`]
												)}
											</Box>,
										].concat(
											hidePrice
												? []
												: [
														<Box>
															<Box whiteSpace="preserve" fontWeight="semibold">
																<Money
																	amount={given_price}
																	currency={currency}
																	showCurrency
																/>
																{calculated_price ? (
																	<>
																		<Box
																			title="Calculate Price"
																			as="span"
																			fontSize="xs"
																			color="muted"
																		>
																			{" "}
																			/{" "}
																			<Money
																				amount={calculated_price}
																				currency={currency}
																			/>
																		</Box>
																	</>
																) : null}
															</Box>
															{comments ? (
																<Box as="blockquote" maxWidth="sm">
																	{comments}
																</Box>
															) : null}
														</Box>,
													]
										)
								)}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	) : null
}
