import {
	Box,
	Button,
	Col,
	Component,
	Divider,
	Grid,
	Heading,
	Icons,
	Inline,
	Select,
	Stack,
	Text,
	joinAttributes,
} from "@sembark-travel/ui/base"
import {
	Form,
	GetFieldValue,
	MarkdownInputField,
	SelectField,
	SelectInputField,
	SubmissionError,
	SwitchInputField,
	TextInputField,
	isTruthy,
	useFieldValue,
	useForm,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import {
	BORDER_WIDTH_OPTIONS,
	TFontFamily,
	TTripQuotePdfTemplate,
} from "./store"
import { useCallback, useMemo } from "react"
import {
	GreetingsTemplateField,
	JustifiedContentToggleField,
	SelectFontFamilyField,
} from "./SharedFormFields"

export type TEmailPdfTemplateCreatePayload = Omit<
	TTripQuotePdfTemplate,
	"id"
> & {
	variables: Array<{
		id: string
		name: string
		description: string
		placeholder: string
	}>
	font_families: Array<TFontFamily>
	border_style_options?: Array<string>
	section_title_settings_options?: {
		background: Array<string>
		border: Array<string>
		rounded: Array<string>
		textAlign: Array<string>
	}
	transport_and_activity_section_settings: {
		variables: Array<string>
		default_variant: string
		variants: Array<{
			id: string
			name: string
			description: string
		}>
	}
}

export function EditTripQuotePdfTemplateForm({
	onCancel,
	onSubmit,
	initialValues,
}: {
	onCancel: () => void
	onSubmit: (
		data: Pick<TEmailPdfTemplateCreatePayload, "name" | "greetings"> &
			Pick<TTripQuotePdfTemplate, "transport_and_activity_section_settings"> & {
				settings: Omit<
					TEmailPdfTemplateCreatePayload["settings"],
					"fontFamily"
				> & {
					fontFamily: string
				}
			}
	) => Promise<unknown>
	initialValues: TEmailPdfTemplateCreatePayload
	fontFamilyNotice?: string
}) {
	const {
		name,
		greetings,
		settings,
		variables,
		font_families,
		transport_and_activity_section_settings,
		daywise_schedule_section_settings,
		terms_section_settings,
		border_style_options,
		section_title_settings,
		section_title_settings_options,
	} = initialValues
	return (
		<Form<{
			name: string
			settings: TEmailPdfTemplateCreatePayload["settings"]
			section_title_settings: TEmailPdfTemplateCreatePayload["section_title_settings"]
			daywise_schedule_section_settings?: TEmailPdfTemplateCreatePayload["daywise_schedule_section_settings"]
			terms_section_settings?: TEmailPdfTemplateCreatePayload["terms_section_settings"]
			greetings: string
			transport_and_activity_section_settings: Omit<
				TEmailPdfTemplateCreatePayload["transport_and_activity_section_settings"],
				"variant"
			> & {
				variant?: TEmailPdfTemplateCreatePayload["transport_and_activity_section_settings"]["variants"][number]
			}
		}>
			initialValues={{
				name,
				// settings,
				settings: {
					...settings,
					watermarkOpacity: Math.round(
						((settings.watermarkOpacity || 0) / 0.02) * 100
					),
				},
				greetings,
				transport_and_activity_section_settings: {
					...transport_and_activity_section_settings,
					variant: transport_and_activity_section_settings.variants.find(
						(v) => transport_and_activity_section_settings.variant === v.id
					),
				},
				terms_section_settings,
				section_title_settings,
				daywise_schedule_section_settings: daywise_schedule_section_settings
					? {
							...daywise_schedule_section_settings,
							// transform for our radio items
							show_itinerary_at_top: isTruthy(
								daywise_schedule_section_settings.show_itinerary_at_top
							)
								? "1"
								: "0",
							hide_travel_distance: isTruthy(
								daywise_schedule_section_settings.hide_travel_distance
							)
								? "1"
								: "0",
						}
					: undefined,
			}}
			onSubmit={withServerErrors(
				async ({
					settings: {
						justifiedText,
						fontFamily,
						borderStyle,
						watermarkOpacity,
						...otherSettings
					},
					transport_and_activity_section_settings,
					...values
				}) => {
					const { greetings } = values
					const contentWithPlaceholders = [
						greetings,
						otherSettings.watermarkText || "",
					].filter((s) => Boolean(s))
					const invalidPlaceholders: Array<string> = []
					contentWithPlaceholders.forEach((content) => {
						if (!content) return
						// check for non-existing variable placeholders
						const placeholderRegex = /\{\{\s*([\w\d]+)\s*\}\}/gi
						let result
						while ((result = placeholderRegex.exec(content)) !== null) {
							const p = result[1]
							if (
								!variables.find((v) => v.placeholder === "{{" + p.trim() + "}}")
							) {
								invalidPlaceholders.push(p)
							}
						}
					})
					if (invalidPlaceholders.length) {
						throw new Error(
							`Invalid placeholder variables: ${invalidPlaceholders.join(
								", "
							)}. Only available variables are allowed.`
						)
					}
					borderStyle = borderStyle ? borderStyle.toLowerCase() : undefined
					await onSubmit({
						...values,
						settings: {
							...otherSettings,
							borderTopStyle: borderStyle,
							borderRightStyle: borderStyle,
							borderBottomStyle: borderStyle,
							borderLeftStyle: borderStyle,
							fontFamily: fontFamily.id,
							justifiedText: isTruthy(justifiedText) ? 1 : 0,
							watermarkOpacity: watermarkOpacity
								? (watermarkOpacity / 100) * 0.02
								: 0,
						},
						transport_and_activity_section_settings: {
							title: transport_and_activity_section_settings.title,
							variant:
								transport_and_activity_section_settings.variant?.id ||
								transport_and_activity_section_settings.variants[0].id,
							hide_transport_summary_for_same_cabs:
								transport_and_activity_section_settings.hide_transport_summary_for_same_cabs,
							terms: transport_and_activity_section_settings.terms,
						},
					})
					showSnackbar("Template details saved")
				}
			)}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit, form }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<Grid gap="6">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading fontSize="md">Settings</Heading>
									<Text color="muted">
										Configure template settings like fonts and text alignment.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<SelectFontFamilyField fontFamilies={font_families} />
									<JustifiedContentToggleField />
									{border_style_options?.length ? (
										<Inline flexWrap="wrap" gap="4">
											<GetBorderWidthFieldValue settingsName="settings">
												{({ borderWidth, hasBorder, onChange }) => (
													<SelectField
														select={Select}
														name="settings.borderStyle"
														label="Border Style"
														placeholder="e.g. None"
														options={border_style_options}
														onChange={(value, name) => {
															form.change(name as never, value as never)
															if (!value || value === "none") {
																onChange(0)
															} else {
																if (!hasBorder) {
																	onChange(4)
																}
															}
														}}
														optionRenderer={({
															option,
														}: {
															option: string
														}) => {
															const bw = hasBorder
																? Math.max(...borderWidth)
																: BORDER_WIDTH_OPTIONS[1]
															return (
																<Stack>
																	<Text>{option}</Text>
																	{option.toLowerCase() === "none" ? (
																		<Text color="muted" fontSize="sm">
																			No-Border
																		</Text>
																	) : (
																		<Box
																			bgColor={"success"}
																			borderColor="primary_emphasis"
																			style={{
																				width: "100px",
																				height: `${bw * 4 < 10 ? 10 : bw * 4}px`,
																				borderWidth: hasBorder
																					? borderWidth.join("px ") + "px"
																					: `${bw}px`,
																				borderStyle: option,
																			}}
																		></Box>
																	)}
																</Stack>
															)
														}}
													/>
												)}
											</GetBorderWidthFieldValue>
											<GetFieldValue<
												string | undefined
											> name="settings.borderStyle">
												{({ value: borderStyle }) =>
													!borderStyle || borderStyle === "none" ? null : (
														<Inline gap="4">
															{["Top", "Right", "Bottom", "Left"].map(
																(side) => {
																	const fieldName = `settings.border${side}Width`
																	return (
																		<SelectInputField
																			name={fieldName}
																			max={10}
																			min={0}
																			step={1}
																			type="number"
																			label={side + " Width"}
																			placeholder="e.g. 0"
																			onChange={(e) => {
																				const value = e.currentTarget.value
																				form.change(
																					e.currentTarget.name as never,
																					value as never
																				)
																				if (Number(value || "0")) {
																					if (
																						!borderStyle ||
																						borderStyle.toLowerCase() === "none"
																					) {
																						form.change(
																							"settings.borderStyle" as never,
																							border_style_options[1] as never
																						)
																					}
																				}
																			}}
																		>
																			{BORDER_WIDTH_OPTIONS.map((width) => (
																				<option value={width} key={width}>
																					{width}
																				</option>
																			))}
																		</SelectInputField>
																	)
																}
															)}
														</Inline>
													)
												}
											</GetFieldValue>
										</Inline>
									) : null}

									<TextInputField
										name="settings.watermarkText"
										label="Watermark Text"
										type="text"
										placeholder="e.g {{ BRAND_NAME }} • {{ TRIP_ID }} •"
										help={() => {
											return (
												<Stack fontSize="sm">
													<Text>
														You can use following placeholder variables in the
														watermark
													</Text>
													<Box>
														{joinAttributes(
															...variables.map((variable) => (
																<Button
																	key={variable.id}
																	inline
																	title={variable.description}
																	onClick={(
																		e: React.MouseEvent<HTMLButtonElement>
																	) => {
																		e.preventDefault()
																		form.change(
																			"settings.watermarkText" as never,
																			((form.getState().values.settings
																				?.watermarkText || "") +
																				` ${variable.placeholder}`) as never
																		)
																		showSnackbar("Added variable to content.")
																	}}
																>
																	{variable.placeholder}
																</Button>
															))
														)}
													</Box>
												</Stack>
											)
										}}
									/>
									{/* opacity of waterMark */}
									<TextInputField
										name="settings.watermarkOpacity"
										label="Watermark Opacity"
										type="number"
										min={0}
										max={100}
										help={({ value }) => {
											const opacityInput = Number(value) || 0
											const opacity = (opacityInput / 100) * 0.02
											return (
												<>
													{opacityInput === 0 ? (
														<Text>
															<b>Note : </b> Watermark will be not be shown as
															the opacity is zero
														</Text>
													) : opacityInput > 100 || opacityInput < 0 ? (
														<Text color="danger">
															Please enter a value between 0 and 100
														</Text>
													) : (
														<Stack gap="1">
															<Text fontSize="sm" color="muted">
																Section Watermark Preview
															</Text>
															<Inline
																paddingX="4"
																paddingY="4"
																rounded="md"
																borderWidth="1"
																flexWrap="wrap"
																gap="4"
																style={{
																	backgroundImage:
																		"radial-gradient(rgb(68, 76, 247) 0.5px, rgb(255, 255, 255) 0.5px)",
																	backgroundSize: "10px 10px",
																}}
															>
																<div
																	style={{
																		display: "inline-block",
																		width: "100px",
																		height: "30px",
																		background: "black",
																		opacity: opacity,
																	}}
																/>
																<div
																	style={{
																		display: "inline-block",
																		width: "100px",
																		height: "30px",
																		background: "black",
																		opacity: opacity,
																	}}
																/>
																<div
																	style={{
																		display: "inline-block",
																		width: "100px",
																		height: "30px",
																		background: "black",
																		opacity: opacity,
																	}}
																/>
															</Inline>
														</Stack>
													)}
												</>
											)
										}}
									/>
								</Stack>
							</Col>
						</Grid>
						{section_title_settings_options ? (
							<>
								<Divider sm />
								<Grid gap="6">
									<Col sm={12} md={4}>
										<Stack gap="1">
											<Heading fontSize="md">Section Title Settings</Heading>
											<Text color="muted">
												Customize the appearance of section's (e.g.
												Accommodation, Transportation etc.) title
											</Text>
										</Stack>
									</Col>
									<Col>
										<Stack gap="4">
											<Inline gap="4" flexWrap="wrap">
												<SelectInputField
													name="section_title_settings.background"
													label="Background Color"
												>
													{section_title_settings_options.background.map(
														(s) => (
															<option value={s} key={s}>
																{s}
															</option>
														)
													)}
												</SelectInputField>
												<SelectInputField
													name="section_title_settings.border"
													label="Border Position"
												>
													{section_title_settings_options.border.map((s) => (
														<option value={s} key={s}>
															{s}
														</option>
													))}
												</SelectInputField>
												<SelectInputField
													name="section_title_settings.rounded"
													label="Roundness"
												>
													{section_title_settings_options.rounded.map((s) => (
														<option value={s} key={s}>
															{s}
														</option>
													))}
												</SelectInputField>
												<SelectInputField
													name="section_title_settings.textAlign"
													label="Text Align"
												>
													{section_title_settings_options.textAlign.map((s) => (
														<option value={s} key={s}>
															{s}
														</option>
													))}
												</SelectInputField>
											</Inline>
											<GetFieldValue<
												TEmailPdfTemplateCreatePayload["section_title_settings"]
											> name="section_title_settings">
												{({ value: section_title_settings }) => {
													if (!section_title_settings) return null
													return (
														<Stack gap="1">
															<Text fontSize="sm" color="muted">
																Section Title Preview
															</Text>
															<Box
																paddingX="4"
																paddingY="2"
																borderColor="primary_emphasis"
																color="primary"
																fontWeight="semibold"
																bgColor={
																	section_title_settings.background === "fill"
																		? "primary"
																		: undefined
																}
																roundedRight={
																	section_title_settings.rounded === "right"
																		? "md"
																		: undefined
																}
																roundedLeft={
																	section_title_settings.rounded === "left"
																		? "md"
																		: undefined
																}
																roundedBottom={
																	section_title_settings.rounded === "bottom"
																		? "md"
																		: undefined
																}
																roundedTop={
																	section_title_settings.rounded === "top"
																		? "md"
																		: undefined
																}
																rounded={
																	section_title_settings.rounded === "all"
																		? "md"
																		: undefined
																}
																borderLeftWidth={
																	section_title_settings.border === "left" ||
																	section_title_settings.border === "all" ||
																	section_title_settings.border === "vertical"
																		? "4"
																		: "0"
																}
																borderRightWidth={
																	section_title_settings.border === "right" ||
																	section_title_settings.border === "all" ||
																	section_title_settings.border === "vertical"
																		? "4"
																		: "0"
																}
																borderTopWidth={
																	section_title_settings.border === "top" ||
																	section_title_settings.border === "all" ||
																	section_title_settings.border === "horizontal"
																		? "4"
																		: "0"
																}
																borderBottomWidth={
																	section_title_settings.border === "bottom" ||
																	section_title_settings.border === "all" ||
																	section_title_settings.border === "horizontal"
																		? "4"
																		: "0"
																}
																textAlign={
																	section_title_settings.textAlign !== "auto"
																		? section_title_settings.textAlign
																		: undefined
																}
															>
																Sample Title Text
															</Box>
														</Stack>
													)
												}}
											</GetFieldValue>
										</Stack>
									</Col>
								</Grid>
							</>
						) : null}
						<Divider sm />
						<GetFieldValue<typeof settings> name="settings">
							{({ value }) => (
								<Stack gap="2">
									<Grid gap="6">
										<Col sm={12} md={4}>
											<Stack gap="1">
												<Heading fontSize="md">Template for Greetings</Heading>
												<Text color="muted">
													Customize the greetings message according to your
													needs.
												</Text>
											</Stack>
										</Col>
										<Col>
											<GreetingsTemplateField
												fontFamily={value.fontFamily}
												justifiedText={value.justifiedText}
												variables={variables}
											/>
										</Col>
									</Grid>
								</Stack>
							)}
						</GetFieldValue>
						<Divider marginY="0" />
						<Grid gap="6">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading fontSize="md">
										Settings for Transfer and Activity Section
									</Heading>
									<Text color="muted">
										Customize the section title, variant and other settings.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<Component
										initialState={
											transport_and_activity_section_settings.title
												? true
												: false
										}
									>
										{({ state: editing, setState }) =>
											!editing ? (
												<Stack gap="1">
													<Text fontWeight="semibold">Section Title</Text>
													<Inline
														borderWidth="1"
														rounded="md"
														paddingLeft="4"
														bgColor="subtle"
														gap="4"
														justifyContent="between"
														alignItems="center"
													>
														<Text>Using default title settings</Text>
														<Button
															onClick={() => setState(true)}
															level="tertiary"
														>
															<Icons.Pencil />
														</Button>
													</Inline>
												</Stack>
											) : (
												<TextInputField
													name="transport_and_activity_section_settings.title"
													label="Section Title"
													type="text"
													placeholder="e.g Transportation and Activities"
												/>
											)
										}
									</Component>
									<SwitchInputField
										name="transport_and_activity_section_settings.hide_transport_summary_for_same_cabs"
										label="Hide Transport Summary if Same Cab Type is used"
										help="This can be helpful if you always share full itinerary and only want to include cab details. This WON'T be applicable if you have Activities in Quote"
									/>
									{transport_and_activity_section_settings.variants.length >
									1 ? (
										<SelectField
											label="Variant"
											select={Select}
											name="transport_and_activity_section_settings.variant"
											options={transport_and_activity_section_settings.variants}
										/>
									) : null}
									<MarkdownInputField
										name="transport_and_activity_section_settings.terms"
										label="Specific Terms and Notes"
										placeholder="e.g. **Note**: Vehicles will be point-to-point in the mountain areas."
									/>
								</Stack>
							</Col>
						</Grid>
						{daywise_schedule_section_settings ? (
							<>
								<Divider marginY="0" />
								<Grid gap="6">
									<Col sm={12} md={4}>
										<Stack gap="1">
											<Heading fontSize="md">
												Settings for Day Wise Itinerary Section
											</Heading>
											<Text color="muted">
												Customize the settings of Day-wise Itinerary section
											</Text>
										</Stack>
									</Col>
									<Col>
										<Stack gap="4">
											<TextInputField
												label="Itinerary Section Title"
												name="daywise_schedule_section_settings.title"
												help="Leave blank to remove the section title"
												placeholder="e.g. Day Wise Itinerary"
											/>
											<SwitchInputField
												name="daywise_schedule_section_settings.hide_travel_distance"
												label="Hide Approx. Travel Distance from Itinerary"
												help="This can be helpful if the travel distance includes extra safe running kms which should not be shared."
											/>
										</Stack>
									</Col>
								</Grid>
							</>
						) : null}
						<Divider marginY="0" />
						<Grid gap="6">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading fontSize="md">Settings for TnC. Section</Heading>
									<Text color="muted">Customize the section title.</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<TextInputField
										name="terms_section_settings.title"
										label="Section Title"
										type="text"
										placeholder="e.g Terms and Conditions"
									/>
								</Stack>
							</Col>
						</Grid>
						<Divider marginY="0" />
						<Grid gap="0">
							<Col sm={12} md={{ offset: 4, span: 8 }}>
								<Stack gap="4" paddingLeft={{ md: "6" }}>
									<SubmissionError />
									<Inline gap="4">
										<Button type="submit" disabled={submitting}>
											{submitting ? "Please wait..." : "Save Template"}
										</Button>
										<Button
											disabled={submitting}
											onClick={() => onCancel()}
											level="tertiary"
										>
											Cancel
										</Button>
									</Inline>
								</Stack>
							</Col>
						</Grid>
					</Stack>
				</form>
			)}
		</Form>
	)
}

function GetBorderWidthFieldValue({
	settingsName,
	children,
}: {
	settingsName: string
	children: (props: {
		borderWidth: [top: number, right: number, bottom: number, left: number]
		hasBorder: boolean
		onChange: (borderWidth: number) => void
	}) => React.ReactNode
}) {
	const { value: top, onChange: changeTop } = useFieldValue<number | "">(
		settingsName + ".borderTopWidth"
	)
	const { value: right, onChange: changeRight } = useFieldValue<number | "">(
		"settings.borderRightWidth"
	)
	const { value: bottom, onChange: changeBottom } = useFieldValue<number | "">(
		"settings.borderBottomWidth"
	)
	const { value: left, onChange: changeLeft } = useFieldValue<number | "">(
		"settings.borderLeftWidth"
	)
	const { borderWidth, hasBorder } = useMemo(() => {
		const border = [
			Number(top || 0),
			Number(right || 0),
			Number(bottom || 0),
			Number(left || 0),
		] as [number, number, number, number]
		return { borderWidth: border, hasBorder: Math.max(...border) > 0 }
	}, [top, right, bottom, left])
	const form = useForm()
	const onChange = useCallback(
		(borderWidth: number) => {
			form.batch(() => {
				changeTop(borderWidth)
				changeRight(borderWidth)
				changeBottom(borderWidth)
				changeLeft(borderWidth)
			})
		},
		[form, changeTop, changeRight, changeBottom, changeLeft]
	)
	return <>{children({ borderWidth: borderWidth, hasBorder, onChange })}</>
}
