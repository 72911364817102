import {
	Box,
	Button,
	Divider,
	Heading,
	Icons,
	Inline,
	RelativeTime,
	Spinner,
	Stack,
	Text,
	joinAttributes,
	Alert,
	useId,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { Markdown } from "@sembark-travel/ui/markdown"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { TTripQuotePdfTemplate } from "./store"
import { isTruthy } from "@sembark-travel/ui/form"
import { FontFamily } from "./FontFamily"
import {
	EditTripQuotePdfTemplateForm,
	TEmailPdfTemplateCreatePayload,
} from "./EditTripQuotePdfTemplateForm"

export function TripQuotePdfTemplates() {
	const xhr = useXHR()
	const { data, mutate, error } = useSWR("/trip-quote-pdf-templates", () =>
		xhr
			.get<{ data: Array<TTripQuotePdfTemplate> }>("/trip-quote-pdf-templates")
			.then((resp) => resp.data)
	)
	const { hasPermission } = useCheckPermissions()
	const [isDialogOpen, openDialog, closeDialog] = useDialog()
	if (!data) {
		if (error) {
			return (
				<Alert status="error">
					{error.message || "Something went wrong. Please try after sometime."}
				</Alert>
			)
		}
		return <Spinner alignCenter padding="4" />
	}
	return (
		<Stack gap="4">
			<Inline justifyContent="between" gap="4" alignItems="center">
				<Inline gap="4" alignItems="center">
					<Inline
						size="12"
						rounded="full"
						bgColor="default"
						borderWidth="1"
						alignItems="center"
						justifyContent="center"
						color="muted"
						fontFamily="mono"
					>
						PDF
					</Inline>
					<Stack gap="1">
						<Heading as="h3">Quote Pdf Template Configurations</Heading>
						<Text>These configuration will be applied to Quote PDFs only.</Text>
					</Stack>
				</Inline>
				{hasPermission(PERMISSIONS.MANAGE_USERS) ? (
					<Box>
						<Button
							onClick={openDialog}
							level={!data.data.length ? "primary" : "secondary"}
							status="primary"
							size="sm"
						>
							<Icons.Pencil /> Edit
						</Button>
					</Box>
				) : null}
			</Inline>
			<Divider marginY="0" />
			<Box>
				{!data.data.length ? (
					<Box padding="4" bgColor="default" rounded="md" borderWidth="1">
						<Text>Using default quote pdf template configurations.</Text>
					</Box>
				) : (
					data.data.map((template) => (
						<Stack key={template.id} gap="4">
							<Text>
								<b>Font Family</b>: {template.settings.fontFamily.name}
							</Text>
							<Text>
								<b>Justified Text</b>:{" "}
								{isTruthy(template.settings.justifiedText)
									? "Justified"
									: "Default"}
							</Text>
							<Text>
								<b>Border Style and Width</b>:{" "}
								{template.settings.borderStyle &&
								template.settings.borderStyle !== "none" ? (
									<Text
										as="span"
										style={{
											padding: "0 4px",
											display: "inline-block",
											borderColor: "silver",
											borderStyle: template.settings.borderStyle,
											borderWidth:
												[
													template.settings.borderTopWidth || 0,
													template.settings.borderRightWidth || 0,
													template.settings.borderBottomWidth || 0,
													template.settings.borderLeftWidth || 0,
												].join("px ") + "px",
										}}
									>
										{template.settings.borderStyle} • Top:
										{template.settings.borderTopWidth || 0}px • Right:
										{template.settings.borderRightWidth || 0}px • Bottom:
										{template.settings.borderBottomWidth || 0}px • Left:
										{template.settings.borderLeftWidth || 0}px
									</Text>
								) : (
									<Text as="span" color={"muted"}>
										none
									</Text>
								)}
							</Text>
							<Text>
								<b>Watermark Text</b>:{" "}
								{template.settings.watermarkText ? (
									template.settings.watermarkText
								) : (
									<Text as="span" color={"muted"}>
										Disabled
									</Text>
								)}
							</Text>
							{/* watermark opacity */}

							<Inline gap="4" alignItems="center">
								<b>Watermark Opacity</b>:{" "}
								<Inline
									paddingX="4"
									paddingY="4"
									rounded="md"
									borderWidth="1"
									flexWrap="wrap"
									gap="4"
									style={{
										backgroundImage:
											"radial-gradient(rgb(68, 76, 247) 0.5px, rgb(255, 255, 255) 0.5px)",
										backgroundSize: "10px 10px",
									}}
								>
									<div
										style={{
											display: "inline-block",
											width: "100px",
											height: "30px",
											background: "black",
											opacity: template.settings.watermarkOpacity,
										}}
									></div>
									<div
										style={{
											display: "inline-block",
											width: "100px",
											height: "30px",
											background: "black",
											opacity: template.settings.watermarkOpacity,
										}}
									></div>
									<div
										style={{
											display: "inline-block",
											width: "100px",
											height: "30px",
											background: "black",
											opacity: template.settings.watermarkOpacity,
										}}
									></div>
								</Inline>
							</Inline>
							{template.section_title_settings ? (
								<Stack gap="1">
									<Text>
										<b>Section Title Settings:</b>{" "}
									</Text>
									<Inline gap="4" flexWrap="wrap" alignItems="center">
										<Box>
											{joinAttributes(
												`Background: ${template.section_title_settings.background}`,
												`Border: ${template.section_title_settings.border}`,
												`Rounded: ${template.section_title_settings.rounded}`,
												`Text Align: ${template.section_title_settings.textAlign}`
											)}
										</Box>
										<Box
											fontSize="sm"
											paddingX="4"
											paddingY="2"
											borderColor="primary_emphasis"
											color="primary"
											fontWeight="semibold"
											roundedRight={
												template.section_title_settings.rounded === "right"
													? "md"
													: undefined
											}
											roundedLeft={
												template.section_title_settings.rounded === "left"
													? "md"
													: undefined
											}
											roundedBottom={
												template.section_title_settings.rounded === "bottom"
													? "md"
													: undefined
											}
											roundedTop={
												template.section_title_settings.rounded === "top"
													? "md"
													: undefined
											}
											rounded={
												template.section_title_settings.rounded === "all"
													? "md"
													: undefined
											}
											bgColor={
												template.section_title_settings.background === "fill"
													? "default"
													: undefined
											}
											borderLeftWidth={
												template.section_title_settings.border === "left" ||
												template.section_title_settings.border === "all" ||
												template.section_title_settings.border === "vertical"
													? "4"
													: "0"
											}
											borderRightWidth={
												template.section_title_settings.border === "right" ||
												template.section_title_settings.border === "all" ||
												template.section_title_settings.border === "vertical"
													? "4"
													: "0"
											}
											borderTopWidth={
												template.section_title_settings.border === "top" ||
												template.section_title_settings.border === "all" ||
												template.section_title_settings.border === "horizontal"
													? "4"
													: "0"
											}
											borderBottomWidth={
												template.section_title_settings.border === "bottom" ||
												template.section_title_settings.border === "all" ||
												template.section_title_settings.border === "horizontal"
													? "4"
													: "0"
											}
										>
											Sample Title Text
										</Box>
									</Inline>
								</Stack>
							) : null}
							<Stack gap="1">
								<Text fontWeight="semibold">Greetings:</Text>
								<FontFamily family={template.settings.fontFamily}>
									<Box
										style={{
											textAlign: isTruthy(template.settings.justifiedText)
												? "justify"
												: undefined,
										}}
										paddingX="4"
										paddingY="2"
										borderWidth="1"
										rounded="md"
										bgColor="default"
									>
										<Markdown>{template.greetings}</Markdown>
									</Box>
								</FontFamily>
							</Stack>
							<Stack gap="1">
								<Text fontWeight="semibold">
									Settings for Transportation and Activity Section
								</Text>
								<Box padding="4" rounded="md" bgColor="default" borderWidth="1">
									<Stack gap="2">
										<Stack gap="px">
											<Text fontSize="sm" fontWeight="semibold" color="muted">
												Section Title
											</Text>
											<Text>
												{template.transport_and_activity_section_settings
													.title || "Using default settings"}
											</Text>
										</Stack>
										<Stack gap="px">
											<Text fontSize="sm" fontWeight="semibold" color="muted">
												Section Variant
											</Text>
											<Text>
												{
													template.transport_and_activity_section_settings
														.variant
												}
											</Text>
										</Stack>
										<Stack gap="px">
											<Text fontSize="sm" fontWeight="semibold" color="muted">
												Hide Transport Summary if Same Cab Type is used?
											</Text>
											<Text>
												{isTruthy(
													template.transport_and_activity_section_settings
														.hide_transport_summary_for_same_cabs
												)
													? "Yes, Hide Transport Summary"
													: "No, Show Day Wise Transport Summary"}
											</Text>
										</Stack>
										<Stack gap="px">
											<Text fontSize="sm" fontWeight="semibold" color="muted">
												Specific Terms and Notes
											</Text>
											<Markdown>
												{template.transport_and_activity_section_settings
													.terms || "N/A"}
											</Markdown>
										</Stack>
									</Stack>
								</Box>
							</Stack>
							<Stack gap="1">
								<Text fontWeight="semibold">
									<Icons.SwitchHorizontal rotate="90" /> Day Wise Itinerary
									Settings
								</Text>
								<Stack
									gap="4"
									padding="4"
									rounded="md"
									bgColor="default"
									borderWidth="1"
								>
									<Stack gap="px">
										<Text fontSize="sm" fontWeight="semibold" color="muted">
											Section Title
										</Text>
										<Text>
											{template.daywise_schedule_section_settings?.title ||
												"N/A"}
										</Text>
									</Stack>
									<Stack gap="px">
										<Text fontSize="sm" fontWeight="semibold" color="muted">
											Travel Distance
										</Text>
										<Text>
											{isTruthy(
												template.daywise_schedule_section_settings
													?.hide_travel_distance
											)
												? "Always Hide"
												: "Always Show"}
										</Text>
									</Stack>
								</Stack>
							</Stack>
							<Stack gap="1">
								<Text fontWeight="semibold">
									<Icons.Support rotate="90" /> Terms Settings
								</Text>
								<Stack
									gap="4"
									padding="4"
									rounded="md"
									bgColor="default"
									borderWidth="1"
								>
									<Stack gap="px">
										<Text fontSize="sm" fontWeight="semibold" color="muted">
											Section Title
										</Text>
										<Text>{template.terms_section_settings?.title}</Text>
									</Stack>
								</Stack>
							</Stack>
							<Text color="muted" fontSize="sm">
								{joinAttributes(
									template.updated_by && template.updated_at ? (
										<>
											Updated by {template.updated_by.name},{" "}
											<RelativeTime timestamp={template.updated_at} />
										</>
									) : undefined,
									<>
										Created by {template.created_by?.name || "You"},{" "}
										<RelativeTime timestamp={template.created_at} />
									</>
								)}
							</Text>
						</Stack>
					))
				)}
			</Box>
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				title="Update Trip Quote PDF Template"
				lg
			>
				<Dialog.Body>
					<EditTripQuotePdfTemplate
						onCancel={() => {
							closeDialog()
						}}
						onSuccess={() => {
							closeDialog()
							mutate()
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</Stack>
	)
}
function EditTripQuotePdfTemplate({
	onCancel,
	onSuccess,
}: {
	onCancel: () => void
	onSuccess: () => void
}) {
	const xhr = useXHR()
	const id__ = useId()
	const { data } = useSWR(`/trip-quote-pdf-template/update?${id__}`, () =>
		xhr
			.get<{
				data: TEmailPdfTemplateCreatePayload
			}>("/trip-quote-pdf-templates/create")
			.then((resp) => resp.data.data)
	)
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	return (
		<Stack gap="4">
			<EditTripQuotePdfTemplateForm
				initialValues={data}
				onCancel={onCancel}
				onSubmit={async (payload) => {
					await xhr.post("/trip-quote-pdf-templates", payload)
					onSuccess()
				}}
			/>
			<Alert>
				These settings will be applied to all future Quote PDFs. You can
				regenerate the existing Quote PDFs to apply these changes as well.
			</Alert>
		</Stack>
	)
}
