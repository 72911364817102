import { IUser } from "../Users"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"

export type TFontFamily = {
	id: string
	name: string
	url: string | null
}

export type TVariable = {
	id: string
	name: string
	description: string
	placeholder: string
}

export const BORDER_WIDTH_OPTIONS = [0, 4, 8, 12, 16, 20]

type TEmailPdfTemplate = {
	id: number
	name: string
	greetings: string
	settings: {
		fontFamily: TFontFamily
		justifiedText: 0 | 1
		watermarkText?: string
		borderBottomStyle?: string
		borderBottomWidth?: number
		borderLeftStyle?: string
		borderLeftWidth?: number
		borderRightStyle?: string
		borderRightWidth?: number
		borderTopStyle?: string
		borderTopWidth?: number
		borderStyle?: string
	}
	created_at: string
	updated_at?: string
	created_by?: IUser
	updated_by?: IUser
	section_title_settings?: {
		background: "fill" | "none"
		border:
			| "top"
			| "right"
			| "bottom"
			| "left"
			| "horizontal"
			| "vertical"
			| "all"
			| "none"
		rounded: "top" | "right" | "bottom" | "left" | "all" | "none"
		textAlign: "left" | "right" | "center" | "auto"
	}
	transport_and_activity_section_settings: {
		hide_transport_summary_for_same_cabs: 0 | 1
		variant?: string
		terms?: string
		title?: string
	}
	daywise_schedule_section_settings?: {
		title?: string
		show_itinerary_at_top?: 0 | 1 | "0" | "1"
		hide_travel_distance?: 0 | 1 | "0" | "1"
	}
	terms_section_settings: {
		title: string
	}
}

export function useTripQuoteWhatsAppTemplate() {
	const xhr = useXHR()
	const { data } = useSWR(
		"/trip-quote-whatsapp-templates",
		() =>
			xhr
				.get<{
					data: Array<TTripQuotePdfTemplate>
				}>("/trip-quote-pdf-templates", {
					params: {
						include: ["use_fallback"].filter(Boolean).join(","),
					},
				})
				.then((resp) => resp.data.data[0]),
		{ revalidateIfStale: false }
	)
	return data
}

export type TTripQuoteEmailTemplate = TEmailPdfTemplate & {
	settings: {
		fontFamily: TFontFamily
		justifiedText: 0 | 1
	}
	transport_and_activity_section_settings: {
		hide_transport_summary_for_same_cabs: 0 | 1
		variant: string
		terms?: string
		title?: string
	}
	daywise_schedule_section_settings: {
		title: string
		show_itinerary_at_top: 0 | 1 | "0" | "1"
		hide_travel_distance: 0 | 1 | "0" | "1"
	}
}
export type TTripQuotePdfTemplate = TEmailPdfTemplate & {
	settings: {
		fontFamily: TFontFamily
		justifiedText: 0 | 1
		watermarkText?: string
		watermarkOpacity?: number
		borderBottomStyle?: string
		borderBottomWidth?: number
		borderLeftStyle?: string
		borderLeftWidth?: number
		borderRightStyle?: string
		borderRightWidth?: number
		borderTopStyle?: string
		borderTopWidth?: number
		borderStyle?: string
	}
	section_title_settings: {
		background: "fill" | "none"
		border:
			| "top"
			| "right"
			| "bottom"
			| "left"
			| "horizontal"
			| "vertical"
			| "all"
			| "none"
		rounded: "top" | "right" | "bottom" | "left" | "all" | "none"
		textAlign: "left" | "right" | "center" | "auto"
	}
	transport_and_activity_section_settings: {
		hide_transport_summary_for_same_cabs: 0 | 1
		variant?: string
		terms?: string
		title?: string
	}
	daywise_schedule_section_settings?: {
		title?: string
		hide_travel_distance: 0 | 1 | "0" | "1"
	}
}
